import classNames from "classnames";
import { TextItem } from "pdfjs-dist/types/src/display/api";
import { CSSProperties, MouseEvent, forwardRef, useCallback, useEffect, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { Page } from "react-pdf";
import { GridChildComponentProps, ListChildComponentProps } from "react-window";
import { PageCallback } from "react-pdf/dist/cjs/shared/types";
import { useDrag } from "react-dnd";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  bulkAddPageRange,
  bulkAddTogglePage,
  pdfTypeIdSelector,
  pdfTypeSelector,
  pdfViewerStateSelector,
  setGoToIndex, setMostVisiblePageIndex,
} from "@pages/pdfviewer/component/pdfViewerSlice";
import useElementOnScreen from "@pages/pdfviewer/component/hooks/useElementOnScreen";
import { usePdfViewportElement } from "@pages/pdfviewer/component/hooks/pdfViewportElementProvider";
import { useAppDispatch, useAppSelector } from "@hooks";
import Checkbox from "@components/checkbox/checkbox";
import { buildSafeRegExp } from "src/utility/regExp";
import { PageOrientation } from "@services/api/document/models/rotateCaseDocumentModel";
import { DocumentPageOverlayIconTypeEnum } from "@pages/pdfviewer/component/models/documentPageOverlayIconType";
import { useLocalization } from "@components/localization/localizationProvider";
import { PdfToolType } from "../models/pdfTool";
import { PageDimensions, PageDimensionsArray } from "../pageDimensions";
import ThumbnailOverlay from "../thumbnail/thumbnailOverlay/thumbnailOverlay";
import getPageIndexInGrid from "../utils/getPageIndexInGrid";
import { TempMarkingAction } from "../models/tempMarkingAction";
import { MarkingModel } from "../models/markingModel";
import { usePdfSize } from "../hooks/pdfSizeProvider";
import { useGetMarkingsQuery } from "../markingsApi";
import styles from "./pageRenderer.module.scss";
import { PageLoader } from "./pageLoader";
import useHighlightTool from "./useHighlightTool";
import usePageThumbnailIcons from "./usePageThumbnailIcons";
import { PageCanvas } from "./canvas/pageCanvas";

export interface PageListChildData {
  getPageClassName?: (pageIndex: number) => string | undefined;
  isThumbnail?: boolean;
  pdfDimensions: PageDimensionsArray;
  scale?: number;
  renderAnnotationLayer?: boolean;
  visiblePages: number[];
  pageMargin: number;
  pageOrientations?: PageOrientation[];
  onDocumentLoaded?: () => void;
}

export interface PageListChildDataDnd extends PageListChildData {
  documentId: string;
}

interface PageGridChildData extends PageListChildData {
  gridColumnCount: number;
}

interface PageGridChildDataDnd extends PageGridChildData {
  documentId: string;
}

export const PageGrid = (props: GridChildComponentProps<PageGridChildData>) => {
  const pageIndex = getPageIndexInGrid(props.rowIndex, props.columnIndex, props.data.gridColumnCount);
  const pageDimensions = props.data.pdfDimensions[pageIndex];

  const gridStyle: CSSProperties = {
    ...props.style,
    position: "absolute",
    width: pageDimensions?.width,
    height: pageDimensions?.height,
    marginTop: props.data.pageMargin,
    marginLeft: props.data.pageMargin,
  };

  return (
    <PageRenderer
      data={props.data}
      pageIndex={pageIndex}
      pageDimensions={pageDimensions}
      style={gridStyle}
    />
  );
};

export const PageGridDraggable = (props: GridChildComponentProps<PageGridChildDataDnd>) => {
  const pageIndex = getPageIndexInGrid(props.rowIndex, props.columnIndex, props.data.gridColumnCount);
  const pageDimensions = props.data.pdfDimensions[pageIndex];

  const orientation = props.data.pageOrientations?.[pageIndex];

  const isHorizontal = orientation === 90 || orientation === 270;

  const gridStyle: CSSProperties = {
    ...props.style,
    position: "absolute",
    width: pageDimensions?.width,
    height: pageDimensions?.height,
    marginTop: isHorizontal ? props.data.pageMargin + pageDimensions?.height / 5 : props.data.pageMargin,
    marginLeft: props.data.pageMargin,
  };

  const [ , drag ] = useDrag({
    type: "page",
    item: { documentId: props.data.documentId, index: pageIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <PageRenderer
      ref={drag}
      data={props.data}
      pageIndex={pageIndex}
      pageDimensions={pageDimensions}
      style={gridStyle}
      thumbnailMargin={isHorizontal ? pageDimensions?.height / 3 : 8}
    />
  );
};

export const PageList = (props: ListChildComponentProps<PageListChildData>) => {
  const pageDimensions = props.data.pdfDimensions[props.index];

  const { width: canvasWidth  } = usePdfSize();

  const pdfTypeId = useAppSelector(pdfTypeIdSelector);
  const pdfType = useAppSelector(pdfTypeSelector);
  const { data, isLoading } = useGetMarkingsQuery((pdfTypeId && pdfType) ? { pdfTypeId: pdfTypeId, origin: pdfType } : skipToken);

  if (isLoading) {
    return <PageLoader className={props.data.getPageClassName?.(props.index)} />;
  }
  let documentHasMarkings = false;
  if (data) {
    documentHasMarkings = Object.values(data).some((array) => array.length > 0);
  }

  const leftMargin = window.innerWidth / 2 - (canvasWidth / 2) - pageDimensions.width / 2 + 126 / 2;

  const listStyle: CSSProperties = {
    ...props.style,
    position: "absolute",
    width: pageDimensions.width,
    height: pageDimensions.height,
    marginTop: props.data.pageMargin,
    left: "0",
    right: "0",
    marginLeft: "auto",    // dont change to e.g (transform: translate ...). That will cause blurryness
    marginRight: "auto",   // dont change to e.g (transform: translate ...). That will cause blurryness
  };

  return (
    <PageRenderer
      data={props.data}
      pageIndex={props.index}
      isScrolling={props.isScrolling}
      pageDimensions={pageDimensions}
      style={listStyle}
      centerWithMarkings={documentHasMarkings}
      leftMargin={leftMargin}
      onPageLoaded={props.data.onDocumentLoaded}
    />
  );
};

export const PageListDraggable = (props: ListChildComponentProps<PageListChildDataDnd>) => {
  const pageDimensions = props.data.pdfDimensions[props.index];

  const listStyle: CSSProperties = {
    ...props.style,
    position: "absolute",
    width: pageDimensions.width,
    height: pageDimensions.height,
    marginTop: props.data.pageMargin,
    left: "0",
    right: "0",
    marginLeft: "auto",    // dont change to e.g (transform: translate ...). That will cause blurryness
    marginRight: "auto",   // dont change to e.g (transform: translate ...). That will cause blurryness
  };

  const [ , drag ] = useDrag({
    type: "page",
    item: { documentId: props.data.documentId, index: props.index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <PageRenderer
      ref={drag}
      data={props.data}
      pageIndex={props.index}
      isScrolling={props.isScrolling}
      pageDimensions={pageDimensions}
      style={listStyle}
    />
  );
};

type PageRendererProps = {
  data: Partial<PageListChildData>;
  pageIndex: number;
  isScrolling?: boolean;
  pdfPageIndex?: number; // when pdf page index is different from document page (i.e. presentation slit into single pages)
  pageDimensions: PageDimensions;
  hideCards?: boolean;
  disableEditing?: boolean;
  hidePresentationBtn?: boolean;
  selectedMarkings?: string[];
  onSelectedMarkingsChange?: (markingIds: string[]) => void;
  style?: CSSProperties;
  className?: string;
  onRenderSuccess?: (page: PageCallback) => void;
  onRenderError?: (error: Error) => void;
  tempMarkings?: MarkingModel[];
  onTempMarkingAction?: (a: TempMarkingAction) => void;
  centerWithMarkings?: boolean;
  leftMargin?: number;
  isLoadingMarkingsEdit?: boolean;
  thumbnailMargin?: number;
  onPageLoaded?: () => void;
};

const PageRenderer = forwardRef<HTMLDivElement, PageRendererProps>((
  props,
  ref,
) => {
  const pdfViewerState = useAppSelector(pdfViewerStateSelector);
  const dispatch = useAppDispatch();
  const localizer = useLocalization();

  const pageRotated = props.data.pageOrientations?.[props.pageIndex] != null && props.data.pageOrientations?.[props.pageIndex] > 0;
  const pageHasRotationMetadata = props.data.pdfDimensions?.[props.pageIndex].rotation != 0 || pageRotated;

  const { thumbnailOverlayIcons } = usePageThumbnailIcons(props.pageIndex, !props.data.isThumbnail);
  const { setPdfContainerRef, highlightActive } = useHighlightTool({
    pageIndex: props.pageIndex,
    disabled: props.data.isThumbnail,
    scale: props.data.scale,
    onTempMarkingAction: props.onTempMarkingAction,
  });
  const pdfViewportElement = usePdfViewportElement();
  const { setOnScreenElement, isVisible } = useElementOnScreen({
    root: pdfViewportElement,
    rootMargin: "-50% 0px",
    disabled: !pdfViewportElement,
  });

  const [textItems, setTextItems] = useState<TextItem[]>([]);

  const onPageLoadSuccess = useCallback(async (page: PageCallback) => {
    const textContent = await page.getTextContent();
    setTextItems(textContent.items as TextItem[]);
  }, []);


  const highlightText = (text: string, patterns: RegExp[]): string => {
    if (patterns.length) {

      let textStringWithMarks = text;
      for (const pattern of patterns) {
        const splitText = textStringWithMarks.split(pattern);

        if (splitText.length <= 1) {
          return text;
        }

        const matches = text.match(pattern);

        const withMarks = splitText.reduce<string[]>((arr, element, index) => {
          const isMatch = matches?.[index];
          if (isMatch) {
            const stringWithMarks = [
              ...arr,
              element,
              `<em>${matches[index]}</em>`,
            ];
            return stringWithMarks;
          } else {
            return [...arr, element];
          }

        }, []);

        textStringWithMarks = withMarks.join("");
      }

      const splitOnMarks = textStringWithMarks.split("<em>");

      const elementsToReturn = splitOnMarks.map((el, i) => {
        if (el.match("</em>")) {

          const withoutEndMarkSplit = el.split("</em>");

          // if there are overlapping matches, there will be multiple </em> tags and thus withoutEndMarkSplit will be longer than 2.
          // in this case all elements besides the last should be highlighted.
          const partOfStringToHighlight = withoutEndMarkSplit.slice(0, withoutEndMarkSplit.length - 1).join();


          return (
            [

              <mark className={styles.markedText} key={i}>
                {partOfStringToHighlight}
              </mark>,


              withoutEndMarkSplit[withoutEndMarkSplit.length - 1],
            ]
          );
        } else {
          return [el];
        }
      });

      return renderToStaticMarkup(<>{elementsToReturn.flat()}</>);

    } else {
      return text;
    }

  };

  useEffect(() => {
    if (isVisible) {
      dispatch(setMostVisiblePageIndex(props.pageIndex));
    }
  }, [dispatch, isVisible, props.pageIndex]);


  // based on https://github.com/wojtekmaj/react-pdf/issues/614
  const textRenderer = useCallback((layer: {
    pageIndex: number;
    pageNumber: number;
    itemIndex: number;
  } & TextItem) => {

    const getTextItemWithNeighbors = (itemIndex: number, span = 2) => {

      return textItems
        .slice(Math.max(0, itemIndex - span), itemIndex + 1 + span)
        .filter(Boolean)
        .map((item) => item.str)
        .join(" ")
        .replace(/  +/g, " ");
    };

    const getIndexRange = (string: string, pattern: RegExp) => {
      const match = pattern.exec(string);

      if (match) {
        return { start: match.index, end: match.index + match[0].length };
      }
    };

    const matchAcrossNeighbors = (text: string, hitText: string, itemIndex: number): RegExp | null => {
      const pattern = buildSafeRegExp(hitText, "i"); // match case-insensitive

      const matches = text.match(pattern);

      if (matches) {
        return pattern;
      }

      const textItemWithNeighbors = getTextItemWithNeighbors(itemIndex);

      const matchInNeighbors = getIndexRange(textItemWithNeighbors, pattern);

      if (!matchInNeighbors) {
        // No match
        return null;
      }

      const itemInNeighbors = getIndexRange(textItemWithNeighbors, buildSafeRegExp(text));

      // exact match
      if (!itemInNeighbors || matchInNeighbors.end < itemInNeighbors?.start || matchInNeighbors.start > itemInNeighbors?.end) {
        return null;
      }
      // Match found was partially in the line we're currently rendering. Now
      // we need to figure out what does "partially" exactly mean

      // Find partial match in a line
      const indexOfCurrentTextItemInMergedLines = textItemWithNeighbors.indexOf(
        text,
      );

      const matchIndexStartInTextItem = Math.max(
        0,
        matchInNeighbors.start - indexOfCurrentTextItemInMergedLines,
      );

      const matchIndexEndInTextItem =
        matchInNeighbors.end - indexOfCurrentTextItemInMergedLines;


      const partialStringToHighlight = text.slice(
        matchIndexStartInTextItem,
        matchIndexEndInTextItem,
      );

      return buildSafeRegExp(partialStringToHighlight);
    };

    const text = layer.str;

    const hitsOnCurrentPage = pdfViewerState.searchHits?.filter((t) => t.pageIndex === props.pageIndex);

    if (pdfViewerState.searchHits && hitsOnCurrentPage && hitsOnCurrentPage.length > 0) {

      const matchesToHighlight = hitsOnCurrentPage
        .map((element) => matchAcrossNeighbors(text, element.hitText, layer.itemIndex))
        .filter((t): t is RegExp => Boolean(t));

      return highlightText(text, matchesToHighlight);
    } else {
      return text;
    }

  }, [pdfViewerState.searchHits, props.pageIndex, textItems]);

  const pageIsVisible = !props.data.visiblePages || props.data.visiblePages.includes(props.pageIndex);
  const pageClicked = (e: MouseEvent) => {
    if (props.data.isThumbnail) {
      if (pdfViewerState.bulkAdd) {
        if (e.shiftKey) {
          dispatch(bulkAddPageRange(props.pageIndex));
        } else {
          dispatch(bulkAddTogglePage(props.pageIndex));
        }
      } else {
        dispatch(setGoToIndex(props.pageIndex));
      }
    }
  };

  // delay rendering till user stops scrolling
  const [scrolling, setScrolling] = useState(props.isScrolling);
  useEffect(() => {
    if (!props.isScrolling) {
      const delayed = setTimeout(() => setScrolling(false), 300);
      return () => clearTimeout(delayed);
    }
  }, [props.isScrolling]);

  const shouldRender = (): boolean => {
    const maxPageIndex = pdfViewerState.totalPages - 1;
    return (props.pdfPageIndex ?? props.pageIndex) <= maxPageIndex;
  };

  return !shouldRender()
    ? null // don't render pages for empty grid columns
    : (
      <div
        role={props.data.isThumbnail ? "button" : undefined}
        onClick={pageClicked}
        className={classNames(
          styles.pageContainer,
          props.className,
        )}
        key={props.pageIndex}
        style={{ ...props.style,  marginLeft: props.centerWithMarkings ? props.leftMargin : "auto" }}
        ref={setOnScreenElement}
      >

        {!pageIsVisible || scrolling
          ? <PageLoader className={props.data.getPageClassName?.(props.pageIndex)} />
          : (
            <>
              {!props.data.isThumbnail &&
                <PageCanvas
                  pageDimensions={props.pageDimensions}
                  pageIndex={props.pageIndex}
                  scale={props.data.scale}
                  hideCards={props.hideCards}
                  disableEditing={props.disableEditing}
                  selectedMarkings={props.selectedMarkings}
                  onSelectedMarkingsChange={props.onSelectedMarkingsChange}
                  tempMarkings={props.tempMarkings}
                  isLoadingMarkingsEdit={props.isLoadingMarkingsEdit}
                />
              }
              <div ref={ref}>
                {props.data.isThumbnail &&
                  <ThumbnailOverlay
                    iconTypes={thumbnailOverlayIcons}
                  />
                }
                {props.data.isThumbnail && pageHasRotationMetadata &&
                  <ThumbnailOverlay
                    iconTypes={[DocumentPageOverlayIconTypeEnum.Rotation]}
                    alignRight={true}
                    tooltipText={localizer.pageIsRotated()}
                  />
                }
                <Page
                  {...props.pageDimensions}
                  inputRef={setPdfContainerRef}
                  className={classNames(
                    styles.page,
                    {
                      [styles.annotationsActive]: pdfViewerState.activeTool.type === PdfToolType.SelectMarking,
                      [styles.disableDefaultSelection]: highlightActive,
                    },
                    props.data.getPageClassName?.(props.pageIndex),
                  )}
                  pageIndex={props.pdfPageIndex ?? props.pageIndex}
                  onLoadSuccess={(pageCallback) => {
                    onPageLoadSuccess(pageCallback);
                    props.onPageLoaded?.();
                  } }
                  onRenderSuccess={props.onRenderSuccess}
                  onRenderError={props.onRenderError}
                  rotate={props.pageDimensions.rotation}
                  renderTextLayer={!props.data.isThumbnail}
                  renderAnnotationLayer={props.data.renderAnnotationLayer ?? false}
                  customTextRenderer={textRenderer}
                  error={<div>Error</div>}
                />

                {props.data.isThumbnail && pdfViewerState.bulkAdd && (
                  <div className={styles.bulkAddCheckbox} >
                    <Checkbox
                      id={`bulk-add-page-${props.pageIndex}`}
                      checked={pdfViewerState.bulkAdd.pageIndexes.includes(props.pageIndex)}
                      readOnly
                      onClick={pageClicked}
                    />
                  </div>
                )}
              </div>

              {props.data.isThumbnail &&
                <div className={"d-flex justify-content-center margin-top-s"} style={{ marginTop: props.thumbnailMargin ? `${props.thumbnailMargin}px` : undefined }}>{props.pageIndex + 1}</div>
              }
            </>
          )
        }
      </div >
    );
});

export default PageRenderer;


