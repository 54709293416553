import React, { HTMLAttributes, RefObject, useMemo, useRef } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import classNames from "classnames";
import { MarkingModel } from "@pages/pdfviewer/component/models/markingModel";
import { PdfType } from "@pages/pdfviewer/component/pdfViewerSlice";
import { MarkingActiveStateType } from "@pages/pdfviewer/component/models/activeMarkingInfo";
import { MarkingType } from "@pages/pdfviewer/component/models/markingType";
import { useMarkingsPageContext } from "@pages/case/presentations/editPresentationPages/MarkingsPageContext/MarkingsPageContext";
import useElementSize from "src/hooks/useElementSize";
import { PageNumberModel } from "@services/api/document/models/documentPageInfoModel";
import HighlightMarking from "../highlight/highlightMarking";
import MarkingIconBtn from "../shared/markingIconButton/markingIconBtn";
import PageNumber from "../PageNumber/PageNumber";
import styles from "./NewMarkingsPageOverlay.module.scss";
import useNewMarkingClickOutside from "./hooks/useNewMarkingsClickOutside";
import NewVerticalLinesMarking from "./Markings/NewVerticalLinesMarking/NewVerticalLinesMarking";
import NewFocusBoxMarking from "./Markings/NewFocusBoxMarking/NewFocusBoxMarking";
import useNewPageMarkings from "./hooks/useNewPageMarkings";

type BaseProps = {
  children: React.ReactNode;
  pageIndex: number;
  disableEditing: boolean;
  tempMarkings?: MarkingModel[];
  isBigScreen?: boolean;
  scale: number;
  documentId: string;
  pageNumber?: PageNumberModel;
  markingsIdsToShow?: string[];
  isZoomedIn?: boolean;
};

type EditingDisabledProps = BaseProps & {
  disableEditing: true;
  caseId?: string;
};

type EditingEnabledProps = BaseProps & {
  disableEditing: false;
  caseId: string;
};

type NewMarkingsPageOverlayProps = EditingDisabledProps | EditingEnabledProps;

type MatchingRefs = {
  markingRef: React.RefObject<HTMLElement>;
  cardRef: React.RefObject<HTMLElement>;
};

const NewMarkingsPageOverlay = ({ children, pageIndex, disableEditing = false, tempMarkings, scale, documentId, pageNumber, markingsIdsToShow, isBigScreen, caseId, isZoomedIn } : NewMarkingsPageOverlayProps) => {

  useNewMarkingClickOutside();

  const [elementRef, containerSize] = useElementSize();

  const { setHoveredMarkingId, setActiveMarking, hoveredMarkingId, activeMarking, pageSize } = useMarkingsPageContext();

  const markingElementRefs = useRef<{ [id: string]: MatchingRefs }>({});
  
  const { markings: allPageMarkings } = useNewPageMarkings({ pageIndex, pdfType: PdfType.Presentation, documentId });

  const markingsInActivePresentationIds = useMemo(() => allPageMarkings.filter((m) => m.presentationList?.some((sp) => sp.presentationId === documentId)).map((m) => m.id), [allPageMarkings, documentId]);
  
  /** Merge user changes with replies form cache as newer replies information is there */
  const relevantActiveMarking = useMemo((): MarkingModel<false> | undefined => {
    if (!activeMarking) {
      return;
    }
    const cacheMarking = activeMarking.marking.page === pageIndex ? allPageMarkings.find(({ id }) => id === activeMarking.marking.id) : undefined;

    return !cacheMarking
      ? activeMarking.marking
      : {
        ...activeMarking.marking,
        replies: cacheMarking.replies,
      };
  }, [activeMarking, allPageMarkings, pageIndex]);

  const mouseOverElement = (id: string) => {
    if (hoveredMarkingId === id)
      return;

    setHoveredMarkingId(id);
  };

  const presentationMode = allPageMarkings.length !== 0;
  const getDynamicAttributes = (marking: MarkingModel): HTMLAttributes<unknown> | undefined => {
    const onMarkingButtonClicked = () => {
      setActiveMarking({
        marking,
        activeState: MarkingActiveStateType.Edit,
      });
      setHoveredMarkingId(undefined);
    };
    return !relevantActiveMarking && !disableEditing ? { role: "button", onClick: onMarkingButtonClicked } : undefined;
  };

  const mouseLeaveElement = () => {
    setHoveredMarkingId(undefined);
  };

  const getRefsSafe = (id: string): MatchingRefs => {
    if (!markingElementRefs.current[id]) {
      markingElementRefs.current[id] = { markingRef: React.createRef(), cardRef: React.createRef() };
    }
    return markingElementRefs.current[id];
  };

  const getMarkingRef = <T extends HTMLElement>(id: string): RefObject<T> => {
    return getRefsSafe(id).markingRef as RefObject<T>;
  };
  
  const renderMarking = (marking: MarkingModel<false>) => {
    const isHovering = hoveredMarkingId === marking.id;
    const isActive = marking.id === relevantActiveMarking?.id;
    const isActiveInEdit = isActive && activeMarking?.activeState === MarkingActiveStateType.Edit;
    const isEnabled = markingsInActivePresentationIds.includes(marking.id);
    const isFaded =
      presentationMode && !isHovering && !isActiveInEdit && !isEnabled;
    const isCommentFaded = marking.type === MarkingType.Comment && !isActiveInEdit && !isHovering;

    const sharedProps = {
      className: classNames(styles.overlayElement, styles.transitions, {
        "shadow-2": isHovering || isActive,
        [styles.notActive]: relevantActiveMarking && !isActive,
        [styles.faded]: isFaded,
        [styles.commentFaded]: isCommentFaded,
      }),

      onMouseOver: () => mouseOverElement(marking.id),
      onMouseLeave: () => mouseLeaveElement(),
      key: marking.id,
      active: isActive,
      scale,
      ...getDynamicAttributes(marking),
    };

    switch (marking.type) {
      case MarkingType.VerticalLines:
        return <NewVerticalLinesMarking ref={getMarkingRef(marking.id)} marking={marking} faded={isFaded} {...sharedProps} />;
      case MarkingType.FocusBox:
        return <NewFocusBoxMarking ref={getMarkingRef(marking.id)} marking={marking} faded={isFaded} {...sharedProps} />;
      case MarkingType.Highlight:
        return (
          <HighlightMarking
            ref={getMarkingRef(marking.id)}
            marking={marking}
            faded={isFaded}
            {...sharedProps}
            className={classNames(styles.highlight, sharedProps.className)}
          />
        );
      default:
        return (
          <MarkingIconBtn
            ref={getMarkingRef(marking.id)}
            marking={marking}
            isPresentationMode={presentationMode}
            {...sharedProps}
            className={classNames(styles.overlayBtn, sharedProps.className)}
          />
        );
    }
  };

  const shownMarkings = useMemo(() => {
    let filtered = allPageMarkings;
    if (relevantActiveMarking) {
      filtered = filtered.filter((x) => x.id !== relevantActiveMarking.id);
    }
    if (markingsIdsToShow) {
      filtered = filtered.filter((x) => markingsIdsToShow.includes(x.id));
    }
    if (isBigScreen) {
      filtered = filtered.filter((x) => x.type !== MarkingType.Comment);
    }
    return filtered;
  }, [allPageMarkings, relevantActiveMarking, markingsIdsToShow, isBigScreen]);
  
  const leftOffset = useMemo(() => {
    if (!pageSize || !containerSize) {
      return 0;
    }
    
    return (containerSize.width - pageSize?.width) / 2;
  }, [pageSize, containerSize]);
  
  return (
    <DndProvider backend={HTML5Backend}>
      <div ref={elementRef} className={classNames("h-100 relative", styles.container, {
        "pt-5": !isZoomedIn,
      })}>
        <div className={classNames(styles.markingsContainer, "relative")} style={{ left: leftOffset }} >
          {shownMarkings.map((marking) => renderMarking(marking))}

          {tempMarkings?.map((marking) => renderMarking(marking))}

          {pageNumber && <PageNumber caseId={caseId} documentId={documentId} scale={scale} pageNumberData={pageNumber} disableEditing={disableEditing} />}

          {relevantActiveMarking?.page === pageIndex && renderMarking(relevantActiveMarking)}
        </div>
        {children}
      </div>
    </DndProvider>
  );
};

export default NewMarkingsPageOverlay;