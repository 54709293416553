import { GridChildComponentProps } from "react-window";
import { CSSProperties } from "react";
import { useDrag } from "react-dnd";
import { PageOrientation } from "@services/api/document/models/rotateCaseDocumentModel";
import { PageDimensionsArray } from "../../pageDimensions";
import getPageIndexInGrid from "../../utils/getPageIndexInGrid";
import NewPageRenderer from "../NewPageRenderer/NewPageRenderer";

export interface PageGridChildData {
  getPageClassName?: (pageIndex: number) => string | undefined;
  isThumbnail?: boolean;
  pdfDimensions: PageDimensionsArray;
  scale?: number;
  renderAnnotationLayer?: boolean;
  visiblePages: number[];
  pageMargin: number;
  pageOrientations?: PageOrientation[];
  onDocumentLoaded?: () => void;
  documentId: string;
  gridColumnCount: number;
  draggable?: boolean;
}

const PageGrid = ({
  columnIndex,
  data,
  rowIndex,
  style,
  isScrolling,
}: GridChildComponentProps<PageGridChildData>) => {
  const {
    documentId,
    gridColumnCount,
    pageMargin,
    pdfDimensions,
    visiblePages,
    getPageClassName,
    isThumbnail,
    onDocumentLoaded,
    pageOrientations,
    renderAnnotationLayer,
    scale,
    draggable = false,
  } = data;

  
  const pageIndex = getPageIndexInGrid(
    rowIndex,
    columnIndex,
    gridColumnCount,
  );
  const pageDimensions = pdfDimensions[pageIndex];
  const orientation = pageOrientations?.[pageIndex];
  const isHorizontal = orientation === 90 || orientation === 270;

  const gridStyle: CSSProperties = {
    ...style,
    position: "absolute",
    width: pageDimensions?.width,
    height: pageDimensions?.height,
    marginTop: isHorizontal ? pageMargin + pageDimensions?.height / 5 : pageMargin,
    marginLeft: pageMargin,
  };

  const [ , drag ] = useDrag({
    type: "page",
    canDrag: draggable,
    item: { documentId, index: pageIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <NewPageRenderer
      ref={drag}
      data={data}
      pageIndex={pageIndex}
      pageDimensions={pageDimensions}
      style={gridStyle}
      thumbnailMargin={isHorizontal ? pageDimensions?.height / 3 : 8}
      orientation={orientation}
    />
  );
};

export default PageGrid;
