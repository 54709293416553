import React from "react";
import { useDragLayer } from "react-dnd";

const layerStyles: React.CSSProperties = {
  position: "fixed",
  pointerEvents: "none",
  zIndex: 1500,
  left: 0,
  top: 0,
};


type DragLayerProps = {
  acceptedDndTypes: string[];
  render: (item: any, itemType: string) => React.ReactNode;
};

const DragLayer = ({ acceptedDndTypes, render }: DragLayerProps) => {
  const {
    itemType,
    isDragging,
    item,
    initialOffset,
    currentOffset,
    clientOffset,
  } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
    clientOffset: monitor.getClientOffset(),
  }));
  
  if (!isDragging || typeof itemType !== "string" || !acceptedDndTypes.includes(itemType)) {
    return null;
  }

  const getItemStyles = () => {
    if (!initialOffset || !currentOffset || !clientOffset) {
      return {
        display: "none",
      };
    }
  
    const { x, y } = clientOffset;
    const transform = `translate(${x - item.width / 2}px, ${y}px)`;
  
    return {
      transform,
    };
  };
  
  return (
    <div style={{ ...layerStyles }}>
      <div style={{ ...getItemStyles(), width: item.width }}>
        {render(item, itemType)}
      </div>
    </div>
  );
};

export default DragLayer;