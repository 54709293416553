import classNames from "classnames";
import { forwardRef, HTMLAttributes, Ref } from "react";
import { LineAppearance, MARKING_FADED_COLOR, VerticalLinesMarkingModel } from "@pages/pdfviewer/component/models/markingModel";
import ActivationHandles from "../../../shared/activationHandles";
import NewResizableMarking from "../NewResizableMarking/NewResizableMarking";
import styles from "./NewVerticalLinesMarking.module.scss";


type NewVerticalLinesMarkingProps = HTMLAttributes<HTMLDivElement> & {
  marking: VerticalLinesMarkingModel<false>;
  scale: number;
  active: boolean;
  faded: boolean;
};

const linesClassByType = {
  [LineAppearance.Single]: styles.single,
  [LineAppearance.Double]: styles.double,
  [LineAppearance.LeftOnly]: styles.leftOnly,
  [LineAppearance.RightOnly]: styles.rightOnly,
  [LineAppearance.Brackets]: styles.brackets,
};

const NewVerticalLinesMarking = forwardRef((
  { marking, scale, active, faded, className, style, ...childProps }: NewVerticalLinesMarkingProps,
  ref: Ref<HTMLDivElement>,
) => {
  const styleWithCssVariable = {
    ...style,
    "--vertical-lines-color": faded ? MARKING_FADED_COLOR : marking.data.color,
  };

  return (
    <NewResizableMarking
      ref={ref}
      marking={marking}
      scale={scale}
      className={classNames(
        styles.verticalLines,
        linesClassByType[marking.data.lines],
        className,
      )}
      style={styleWithCssVariable}
      active={active}
      resizableHeight
      resizableWidth
      notClickable={!active}
      {...childProps}
    >
      <ActivationHandles
        disabled={active}
        east={marking.data.lines !== LineAppearance.LeftOnly}
        west={marking.data.lines !== LineAppearance.RightOnly}
      />
    </NewResizableMarking>
  );
});

export default NewVerticalLinesMarking;
