import { StorageKeys } from "@infrastructure/storageKeys";

export class Localizer {
  private translations: { [key: string]: string } = {};


  constructor(translations: { [key: string]: string } | null) {
    if (translations) {
      this.translations = this.toLowerKeys(translations);
      localStorage.setItem(StorageKeys.localizations, JSON.stringify(translations));
    } else {
      const localizationAsJson = localStorage.getItem(StorageKeys.localizations);
      if (localizationAsJson) {
        this.translations = this.toLowerKeys(JSON.parse(localizationAsJson));
      }
    }
  }

  private toLowerKeys = (translations: { [key: string]: string }) => {
    const keys = Object.keys(translations);

    const translationsToLowered : Record<string, string> = {};
    keys.forEach((key) => {
      const formattedKey =  key.toLowerCase();
      translationsToLowered[formattedKey] = translations[key];
    });


    return translationsToLowered;
  };

  private formatString = (stringToFormat: string, ...args : string[]) => {
    return stringToFormat.replace(/{(\d+)}/g, (match, number) => {
      return typeof args[number] != "undefined"
        ? args[number]
        : match
      ;
    });
  };

  public login = () : string => this.translations.login ?? "Log ind";
  public loginFailed = () : string => this.translations.loginfailed ?? "Email eller adgangskode er ikke korrekt.";
  public email = () : string => this.translations.email ?? "Email";
  public emailRequired = () : string => this.translations.emailrequired ?? "E-mail er påkrævet";
  public emailValid = () : string => this.translations.emailvalid ?? "Indtast valid E-mail";
  public emailPlaceholder = () : string => this.translations.emailplaceholder ?? "Indtast din email";
  public emailPlaceholderEmployee = () : string => this.translations.emailplaceholderemployee ?? "Indtast medarbejderens email";

  public user = () : string => this.translations.user ?? "Bruger";
  public users = () : string => this.translations.users ?? "Brugere";
  public employee = () : string => this.translations.employee ?? "Medarbejder";
  public dashboard = () : string => this.translations.dashboard ?? "Dashboard";
  public profile = () : string => this.translations.profile ?? "Profil";
  public emailTemplate = () : string => this.translations.emailtemplate ?? "Emailskabelon";
  public logout = () : string => this.translations.logout ?? "Log ud";
  public settings = () : string => this.translations.settings ?? "Indstillinger";

  public errorOccuredTitle = () : string => this.translations.erroroccuredtitle ?? "Vi er stødt på en fejl";
  public errorCode = () : string => this.translations.errorcode ?? "Fejlkode:";
  public errorCodeMissing = () : string => this.translations.errorcodemissing ?? "Fejlkode mangler";
  public errorOccuredContent = () : string => this.translations.erroroccured ?? "Prøv igen - Fortsætter fejlen kan du sende en mail til supporten.";
  public errorDocumentMarkingUpdate = () : string => this.translations.errordocumentmarkingupdate ?? "Der opstod en uventet fejl i systemet, som forhindrede gennemførelsen af opdateringen. Venligst opdater siden og prøv igen.";
  public errorOccuredAt = (atTime: string, atDate: string) : string => this.translations.erroroccuredat ? this.formatString(this.translations.erroroccuredat, atTime, atDate) : `Fejlen opstod kl. ${atTime} d. ${atDate}.`;
  public sendEmail = () : string => this.translations.sendemail ?? "Send mail";
  public backToIndex = () : string => this.translations.backtoindex ?? "Tilbage til forsiden";

  public pageIsRotated = () : string => this.translations.pageisrotated ?? "Rotation kan forskyde markeringer og forårsage fejl";

  public applicationIsOffline = () : string => this.translations.applicationisoffline ?? "Applikationen er offline";
  public reloadApp = () : string => this.translations.reloadapp ?? "Genindlæs";
  public pageNotFound = () : string => this.translations.pagenotfound ?? "404 - Siden blev ikke fundet";
  public noResultsFound = () : string => this.translations.noresultsfound ?? "Ingen resultater fundet";
  public language = () : string => this.translations.language ?? "Sprog";
  public danish = () : string => this.translations.danish ?? "Dansk";
  public english = () : string => this.translations.english ?? "Engelsk";

  public list = () : string => this.translations.list ?? "Liste";
  public edit = () : string => this.translations.edit ?? "Rediger";
  public rename = () : string => this.translations.rename ?? "Omdøb";
  public renameSection = () : string => this.translations.renamesection ?? "Omdøb sektion";
  public sortascending = () : string => this.translations.sortascending ?? "Sortér stigende";
  public sortdescending = () : string => this.translations.sortdescending ?? "Sortér faldende";
  public enableDateSorting = () : string => this.translations.enabledatesorting ?? "Sortér efter dato";
  public changeDocumentSortingWarning = () : string => this.translations.changedocumentsortingwarning ?? "Denne handling vil ændre den aktuelle dokumentsorteringsrækkefølge.";
  public navigateToDifferentDocumentDescription = () : string => this.translations.navigatetodifferentdocumentdescription ?? "Naviger til et andet dokument i sagen.";
  public navigateToDifferentDocumentTitle = () : string => this.translations.navigatetodifferentdocumenttitle ?? "Naviger til et andet dokument";
  public changeDocumentSorting = () : string => this.translations.changedocumentsorting ?? "Ændre dokumentsortering";
  public manualyOrderFiles = () : string => this.translations.manualyorderfiles ?? "Sortér manuelt";
  public dateOrderText = () : string => this.translations.manualordertext ?? "Filerne i dette afsnit er manuelt bestilt. Aktivering af datosortering vil fjerne denne ordre.";
  public manualOrderText = () : string => this.translations.manualordertext ?? "Sektioner er ordnet faldende efter dato. Manuel omarrangering af filer inden for en sektion deaktiverer datosortering.";
  public removeSorting = () : string => this.translations.removesorting ?? "Fjern sortering";
  public remove = () : string => this.translations.remove ?? "Fjern";
  public extractExhibitNumberFromTitle = () : string => this.translations.extractexhibitnumberfromtitle ?? "Uddrag bilagsnummer fra titel";
  public create = () : string => this.translations.create ?? "Opret";
  public save = () : string => this.translations.save ?? "Gem";
  public saved = () : string => this.translations.saved ?? "Gemt";
  public saving = () : string => this.translations.saving ?? "Gemmer";
  public couldNotSave = () : string => this.translations.couldnotsave ?? "Kunne ikke gemme";
  public cancel = () : string => this.translations.cancel ?? "Annuller";
  public confirm = () : string => this.translations.confirm ?? "Bekræft";
  public delete = () : string => this.translations.delete ?? "Slet";
  public discardChanges = () : string => this.translations.discardchanges ?? "Kasser ændringer";
  public status = () : string => this.translations.status ?? "Status";
  public impersonate = () : string => this.translations.impersonate ?? "Impersonate";
  public stopImpersonate = () : string => this.translations.StopImpersonation ?? "Stop impersonation";

  public show = () : string => this.translations.show ?? "Vis";
  public search = () : string => this.translations.search ?? "Søg";
  public searchBarPlaceholder = () : string => this.translations.searchbarplaceholder ?? "Indtast søgning";

  public showInactive = () : string => this.translations.showinactive ?? "Vis inaktive";
  public activeMarkingsDisplayedInTrial = () : string => this.translations.activemarkingsdisplayedintrial ?? "Aktiverede markeringer vises på storskærmen i retsagen.";
  public activeCommentsAppearOnScreen = () : string => this.translations.activecommentsappearonscreen ?? "Aktive kommentarer vises kun på din skærm og ikke på storskærmen i retsagen.";

  public password = () : string => this.translations.password ?? "Adgangskode";
  public passwordRequired = () : string => this.translations.passwordrequired ?? "Adgangskode er påkrævet";
  public passwordPlaceholder = () : string => this.translations.passwordplaceholder ?? "Indtast din adgangskode";

  public newPassword = () : string => this.translations.newpassword ?? "Ny adgangskode";
  public newPasswordRequired = () : string => this.translations.newpasswordrequired ?? "Ny adgangskode er påkrævet";
  public newPasswordPlaceholder = () : string => this.translations.newpasswordplaceholder ?? "Indtast din nye adgangskode";

  public repeatNewPassword = () : string => this.translations.repeatnewpassword ?? "Gentag ny adgangskode";
  public repeatNewPasswordRequired = () : string => this.translations.repeatnewpasswordrequired ?? "Gentag ny adgangskode er påkrævet";
  public repeatNewPasswordPlaceholder = () : string => this.translations.repeatnewpasswordplaceholder ?? "Gentag din nye adgangskode";

  public changePassword = () : string => this.translations.changepassword ?? "Skift adgangskode";

  public changeProfilePicture = () : string => this.translations.changeprofilepicture ?? "Skift profilbillede";
  public deleteProfilePicture = () : string => this.translations.deleteprofilepicture ?? "Slet profilbillede";
  public yourProfilePictureChangedSuccessfully = () : string => this.translations.yourprofilepicturechangedsuccessfully ?? "Dit profilbillede blev opdateret";
  public yourProfilePictureDeletedSuccessfully = () : string => this.translations.yourprofilepicturedeletedsuccessfully ?? "Dit profilbillede blev slettet";

  public employeeProfilePictureChangedSuccessfully = () : string => this.translations.employeeprofilepicturechangedsuccessfully ?? "Medarbejderens profilbillede blev opdateret";
  public employeeProfilePictureDeletedSuccessfully = () : string => this.translations.employeeprofilepicturedeletedsuccessfully ?? "Medarbejderens profilbillede blev slettet";

  public name = () : string => this.translations.name ?? "Navn";
  public pageName = () : string => this.translations.pagename ?? "Navn";

  public firstName = () : string => this.translations.firstname ?? "Fornavn";
  public firstNameRequired = () : string => this.translations.firstnamerequired ?? "Fornavn er påkrævet";
  public firstNamePlaceholder = () : string => this.translations.firstnameplaceholder ?? "Indtast dit fornavn";
  public firstNamePlaceholderEmployee = () : string => this.translations.firstnameplaceholderemployee ?? "Indtast medarbejderens fornavn";

  public lastName = () : string => this.translations.lastname ?? "Efternavn";
  public lastNameRequired = () : string => this.translations.lastnamerequired ?? "Efternavn er påkrævet";
  public lastNamePlaceholder = () : string => this.translations.lastnameplaceholder ?? "Indtast dit efternavn";
  public lastNamePlaceholderEmployee = () : string => this.translations.lastnameplaceholderemployee ?? "Indtast medarbejderens efternavn";

  public initials = () : string => this.translations.initials ?? "Initialer";
  public initialsPlaceholder = () : string => this.translations.initialsplaceholder ?? "Indtast medarbejderens initialer";

  public phone = () : string => this.translations.phone ?? "Telefonnummer";
  public phonePlaceholder = () : string => this.translations.phoneplaceholder ?? "Indtast medarbejderens telefonnummer";
  public phoneInvalid = () : string => this.translations.phonenumberisinvalid ?? "Telefonnummer er ugyldigt";

  public fontFamily = () : string => this.translations.fontfamily ?? "Skrifttype";
  public whiteBackground = () : string => this.translations.whitebackground ?? "Hvid baggrund";

  public administrator = () : string => this.translations.administrator ?? "Administrator";

  public profileImage = () : string => this.translations.profileimage ?? "Profilbillede";
  public profileImagePlaceholder = () : string => this.translations.addfile ?? "Tilføj fil";

  public active = () : string => this.translations.active ?? "Aktiv";
  public activeRequired = () : string => this.translations.activerequired ?? "Aktiv er påkrævet";
  public canLogIn = () : string => this.translations.canlogin ?? "Må logge ind";
  public notActivedYet = () : string => this.translations.notactivedyet ?? "Endnu ikke aktiveret";
  public employeeActivation = () : string => this.translations.employeeactivation ?? "Aktivering";
  public employeeHasBeenActivatedAt = () : string => this.translations.employeehasbeenactivatedat ?? "Medarbejderen har gennemført aktivering den: ";
  public resendActivationLink = () : string => this.translations.resendactivationlink ?? "Gensend aktiveringslink";
  public activationEmailSent = () : string => this.translations.activationemailsent ?? "Aktiveringsmail sendt";

  public type = () : string => this.translations.type ?? "Type";

  public subject = () : string => this.translations.subject ?? "Emne";
  public subjectRequired = () : string => this.translations.subjectrequired ?? "Emne er påkrævet";
  public subjectPlaceholder = () : string => this.translations.subjectplaceholder ?? "Indtast et emne";

  public bodyMarkdown = () : string => this.translations.content ?? "Indhold";
  public bodyMarkdownRequired = () : string => this.translations.contentrequired ?? "Indhold er påkrævet";
  public bodyMarkdownPlaceholder = () : string => this.translations.contentplaceholder ?? "Indtast indhold";

  public preview = () : string => this.translations.preview ?? "Forhåndsvisning";

  public yearMonthDayDateType = () : string => this.translations.yearmonthdaydatetype ?? "Vis dato, måned, år";
  public yearMonthDateType = () : string => this.translations.yearmonthdatetype ?? "Vis måned, år";
  public yearDateType = () : string => this.translations.yeardatetype ?? "Vis år";
  public noDateDateType = () : string => this.translations.nodatedatetype ?? "Udateret";
  public dateType = () : string => this.translations.datetype ?? "Dato type";

  public maxLength3 = () : string => this.translations.maxlength3 ?? "Der kan maksimalt angives 3 karakterer";
  public maxLength15 = () : string => this.translations.maxlength15 ?? "Der kan maksimalt angives 15 karakterer";
  public maxLength40 = () : string => this.translations.maxlength40 ?? "Der kan maksimalt angives 40 karakterer";
  public maxLength60 = () : string => this.translations.maxlength60 ?? "Der kan maksimalt angives 60 karakterer";
  public maxLength200 = () : string => this.translations.maxlength200 ?? "Der kan maksimalt angives 200 karakterer";
  public maxLength255 = () : string => this.translations.maxlength255 ?? "Der kan maksimalt angives 255 karakterer";
  public maxLength150 = () : string => this.translations.maxlength150 ?? "Der kan maksimalt angives 150 karakterer";
  public maxLength100 = () : string => this.translations.maxlength100 ?? "Der kan maksimalt angives 100 karakterer";
  public maxLength2000 = () : string => this.translations.maxlength2000 ?? "Der kan maksimalt angives 2000 karakterer";
  public all = () : string => this.translations.all ?? "Alle";

  public title = () : string => this.translations.title ?? "Titel";
  public titleRequired = () : string => this.translations.titlerequired ?? "Titel er påkrævet";
  public titleLengthValidation = () : string => this.translations.titlelengthvalidation ?? "Titel må maks være 150 karaktere";
  public titlePlaceholder = () : string => this.translations.titleplaceholder ?? "Indtast sagens titel";


  public section = () : string => this.translations.section ?? "Sektion";
  public sectionwascreated = () : string => this.translations.sectionwascreated ?? "Sektionen er oprettet";

  public addSection = () : string => this.translations.addsection ?? "Tilføj sektion";
  public deleteSection = () : string => this.translations.deletesection ?? "Slet sektion";
  public deleteSectionAreYouSure1 = () : string => this.translations.deletesectionareyousure1 ?? "Er du sikker på, at du vil slette sektionen?";
  public sectionContains = () : string => this.translations.sectioncontains ?? "Sektionen indeholder";
  public deleteSectionAreYouSure2 = () : string => this.translations.deletesectionareyousure2 ?? "dokumenter / sektioner. Alle vil blive slettet.";
  public createSectionHint1 = () : string => this.translations.createsectionhint1 ?? "Her kan du oprette en sektion.";
  public createSectionHint2 = () : string => this.translations.createsectionhint2 ?? "Sektioner organiserer indholdet i ekstraktet, og vises i indholdsfortegnelsen.";

  public description = () : string => this.translations.description ?? "Beskrivelse";
  public descriptionRequired = () : string => this.translations.descriptionrequired ?? "Beskrivelse er påkrævet";
  public descriptionPlaceholder = () : string => this.translations.descriptionplaceholder ?? "Indtast en beskrivelse af sagen";

  public creationDate = () : string => this.translations.creationdate ?? "Oprettelsesdato";
  public client = () : string => this.translations.client ?? "Klient";
  public clientRequired = () : string => this.translations.clientrequired ?? "Klient er påkrævet";
  public clientPlaceholder = () : string => this.translations.clientplaceholder ?? "Indtast eller vælg sagens klient";

  public date = () : string => this.translations.date ?? "Dato";
  public dateformat = () : string => this.translations.dateformat ?? "Datoformat";
  public dateformattableofcontents = () : string => this.translations.dateformattableofcontents ?? "Datoformat indholdsfortegnelse";
  public dateMissing = () : string => this.translations.datemissing ?? "Dato mangler";
  public appendixNumberMissing = () : string => this.translations.appendixnumbermissing ?? "Bilagsnummer mangler";

  public showCompletedCases = () : string => this.translations.showcompletedcases ?? "Afsluttede sager";
  public ongoingCases = () : string => this.translations.ongoingcases ?? "Igangværende sager";
  public completedCases = () : string => this.translations.completedcases ?? "Afsluttede sager";
  public cases = () : string => this.translations.cases ?? "Sager";
  public casesMenuActivity = () : string => this.translations.casesmenuactivity ?? "Aktivitet";
  public case = () : string => this.translations.case ?? "Sag";
  public createCase = () : string => this.translations.createcase ?? "Opret sag";
  public courtCaseNumber = () : string => this.translations.courtcasenumber ?? "Rettens sagsnummer";
  public internalCaseNumber = () : string => this.translations.internalcasenumber ?? "Internt sagsnummer";
  public internalCaseNumberPlaceholder = () : string => this.translations.internalcasenumberplaceholder ?? "Indtast internt sagsnummer";
  public courtCaseNumberPlaceholder = () : string => this.translations.courtcasenumberplaceholder ?? "Indtast rettens sagsnummer";
  public presentations = () : string => this.translations.presentations ?? "Præsentationer";
  public participants = () : string => this.translations.participants ?? "Deltagere";
  public summary = () : string => this.translations.summary ?? "Generelt";
  public createCaseHint1 = () : string => this.translations.createcase_hint1 ?? "Her opretter du sagen.";
  public createCaseHint2 = () : string => this.translations.createcase_hint2 ?? "Alle oplysninger kan ændres igen når sagen er oprettet.";
  public theCaseWasCreated = () : string => this.translations.thecasewascreated ?? "Sagen blev oprettet";
  public next = () : string => this.translations.next ?? "Næste";
  public previous = () : string => this.translations.previous ?? "Forrige";
  public selectSPresentations = () : string => this.translations.selectpresentations ?? "Præsentation(er)";
  public presentationsRequired = () : string => this.translations.presentationsrequired ?? "Præsentation(er) er påkrævet";
  public addPresentation = () : string => this.translations.addpresentation ?? "Tilføj præsentation";
  public addSPresentations = () : string => this.translations.addpresentations ?? "Tilføj præsentation(er)";
  public presentationTitlePlaceholder = () : string => this.translations.presentationtitleplaceholder ?? "Indtast præsentationstitel";
  public addPresentationHint1 = () : string => this.translations.addpresentation_hint1 ?? "Her opretter du præsentationer.";
  public addPresentationHint2 = () : string => this.translations.addpresentation_hint2 ?? "En præsentation er en samling af hele eller dele af dokumenter, som du kan oprette markeringer, nedslag, og indstregninger i, samt bruge til eksport. Du kan også oprette, ændre eller slette præsentationer, når sagen er oprettet.";
  public presentationNameIsRequired = () : string => this.translations.presentationnameisrequired ?? "Præsentationsnavn er påkrævet";
  public addParticipantsHint1 = () : string => this.translations.addparticipants_hint1 ?? "Her registrerer du sagens deltagere.";
  public addParticipantsSearchPlaceholder = () : string => this.translations.addparticipants_search_placeholder ?? "Søg efter navn eller e-mailadresse";
  public addParticipantsSearchHint = () : string => this.translations.addparticipants_search_hint ?? "Deltagere får adgang til alle funktioner i arbejdsrummet.";
  public internal = () : string => this.translations.internal ?? "Interne";
  public addParticipant = () : string => this.translations.addparticipant ?? "Tilføj deltager";
  public addParticipants = () : string => this.translations.addparticipants ?? "Tilføj deltagere";
  public theParticipantsWasAdded = () : string => this.translations.theparticipantswasadded ?? "Deltagerne blev tilføjet";
  public atleastOneParticipant = () : string => this.translations.atleastoneparticipant ?? "Der skal mindst være én deltager på en sag";

  public theParticipantWasRemoved = () : string => this.translations.theparticipantwasremoved ?? "Deltageren blev fjernet";
  public theParticipantWasUpdated = () : string => this.translations.theparticipantwasupdated ?? "Deltageren blev opdateret";
  public removeParticipantFromCasePromt = () : string => this.translations.removeparticipantfromcasepromt ?? "Er du sikker på, at du ønsker at slette deltageren fra sagen? Handlingen kan ikke fortrydes.";

  public joyRideConstantsDocumentViewerToolboxMarking = () : string => this.translations.joyrideconstantsdocumentviewertoolboxmarking ?? "Her finder du værktøjer til at arbejde med dokumentet. Du kan kommentere, indstrege samt markere tekst eller områder.";
  public joyRideConstantsDocumentViewerToolboxSelector = () : string => this.translations.joyrideconstantsdocumentviewertoolboxselector ?? "Her skifter du mellem at interagere med dokumentet og at kopiere tekst.";
  public joyRideConstantsDocumentViewerToolboxBottombar = () : string => this.translations.joyrideconstantsdocumentviewertoolboxbottombar ?? "Her har du adgang til sagens præsentationer. Du kan folde panelet ud, se præsentationerenes indhold samt tilføje ydereligere sider til dem.";
  public joyRideConstantsDocumentDocumentStatus = () : string => this.translations.joyrideconstantsdocumentviewerdocumentstatus ?? "Her kan du se dokumentets status. Dine ændringer gemmes automatisk.";


  public joyRideConstantsDocumentViewerSidebar = () : string => this.translations.joyrideconstantsdocumentviewersidebar ?? "Her får du overblik over dokumentets indhold. Du kan folde panelet ud for at skifte visning til indholdsfortegnelse.";

  public counterparts = () : string => this.translations.counterparts ?? "Modparter";
  public counterpart = () : string => this.translations.counterpart ?? "Modpart";
  public counterpartsPlaceholder = () : string => this.translations.counterpartsplaceholder ?? "Tilføj sagens modparter";
  public typeNameOfCounterpartToAdd = () : string => this.translations.typenameofcounterparttoadd ?? "Indtast navnet på modparten for at tilføje dem";
  public yourProfileWasUpdated = () : string => this.translations.yourprofilewasupdated ?? "Din profil blev opdateret";
  public passwordChangedSuccess = () : string => this.translations.passwordchangedsuccess ?? "Din adgangskode er blevet ændret, og du kan nu logge ind med din nye adgangskode.";
  public typeNameOfClientToAdd = () : string => this.translations.typenameofclienttoadd ?? "Indtast navnet på klienten for at tilføje dem";

  public documents = () : string => this.translations.documents ?? "Dokumenter";
  public wholeDocument = () : string => this.translations.wholedocument ?? "Hele dokumentet";
  public leftOut = () : string => this.translations.leftout ?? "Udeladt";
  public documentRemovedFromDraft = () : string => this.translations.documentremovedfromdraft ?? "Dokumentet blev fjernet fra kladden";
  public uploadedBy = () : string => this.translations.uploadedby ?? "Uploadet af";
  public uploadedAt = () : string => this.translations.uploadedat ?? "Uploadet d.";
  public lastChanged = () : string => this.translations.lastchanged ?? "Sidst ændret";
  public lastActivity = () : string => this.translations.lastactivity ?? "Sidste aktivitet";

  public courtRecords = () : string => this.translations.courtrecords ?? "Retsbøger";
  public legalDocuments = () : string => this.translations.legaldocuments ?? "Processkrifter";
  public annex = () : string => this.translations.annex ?? "Bilagsnummer";
  public annexPlaceholder = () : string => this.translations.annexplaceholder ?? "Indtast dokuments bilagsnummer";

  public extract = () : string => this.translations.extract ?? "Ekstrakt";
  public currentlyNoExtracts = () : string => this.translations.currentlynoextracts ?? "Der er ikke nogen ekstrakt-kladder i sagen.";
  public draftWasDeleted = () : string => this.translations.draftwasdeleted ?? "Kladden er blevet slettet";
  public helpAppendix = () : string => this.translations.helpappendix ?? "Hjælpebilag";
  public materialCollection = () : string => this.translations.materialcollection ?? "Materialesamling";
  public other = () : string => this.translations.other ?? "Andet";
  public draft = () : string => this.translations.draft ?? "Kladde";

  public deleteDocument = () : string => this.translations.deletedocument ?? "Slet dokument";
  public theDocumentWasDeleted = () : string => this.translations.thedocumentwasdeleted ?? "Dokumentet blev slettet";
  public documentDeleteNotAllowededDescription = () : string => this.translations.documentdeletenotallowededdescription ?? "Du har ikke rettigheder til at slette dette dokument";
  public extractDraftDeleteNotAllowededDescription = () : string => this.translations.extractDraftDeleteNotAllowededDescription ?? "Du har ikke rettigheder til at slette denne ekstrakt-kladde";

  public createdBy = () : string => this.translations.createdby ?? "Oprettet af";
  public steps = () : string => this.translations.steps ?? "Trin";
  public step = () : string => this.translations.step ?? "Trin";
  public editPresentation = () : string => this.translations.editpresentation ?? "Rediger præsentation";
  public presentationEmpty = () : string => this.translations.presentationempty ?? "Præsentationen er tom";
  public presentationEmptyDescription = () : string => this.translations.presentationemptydescription ?? "Præsentationen er i øjeblikket tom. Vælg venligst en anden præsentation for at kunne påbegynde redigering.";
  public editPresentationTitle = () : string => this.translations.editpresentationtitle ?? "Redigér titel";
  public thePresentationWasUpdated = () : string => this.translations.thepresentationwasupdated ?? "Præsentationen blev opdateret";
  public deletePresentation = () : string => this.translations.deletepresentation ?? "Slet præsentation";
  public thePresentationWasDeleted = () : string => this.translations.thepresentationwasdeleted ?? "Præsentationen blev slettet";
  public thePresentationsWasAdded = () : string => this.translations.thepresentationswasadded ?? "Præsentationerne blev tilføjet";
  public presentationEditDeleteNotAllowedDescription = () : string => this.translations.presentationeditdeletenotalloweddescription ?? "Du har ikke rettigheder til at modificere præsentationen";
  public presentationEditSpeechNotesHint1 = () : string => this.translations.presentationeditspeechnoteshint1 ?? "Der er ikke oprettet talenoter på denne side i præsentationen.";
  public presentationEditSpeechNotesHint2 = () : string => this.translations.presentationeditspeechnoteshint2 ?? "Tryk på ‘+’ for at komme i gang.";
  public byLowerCase = () : string => this.translations.bylowercase ?? "af";

  public theEmailTemplateWasUpdated = () : string => this.translations.theemailtemplatewasupdated ?? "Emailskabelonen blev opdateret";

  public theEmployeeWasCreated = () : string => this.translations.theemployeewascreated ?? "Medarbejderen blev oprettet";
  public theEmployeeWasUpdated = () : string => this.translations.theemployeewasupdated ?? "Medarbejderen blev opdateret";

  public theEmployeeCantBeDeletedIsTheOnlyAdministrator = () : string => this.translations.theemployeecantbedeletedistheonlyadministrator ?? "Medarbejderen kan ikke slettes, da denne er eneste administrator i systemet";
  public administratorRoleCantBeRemovedTheEmployeeIsTheOnlyAdministrator = () : string => this.translations.administratorrolecantberemovedtheemployeeistheonlyadministrator ?? "Administratorrollen kan ikke fjernes, da medarbejderen er den eneste administrator i systemet";

  public areYouSure = () : string => this.translations.areyousure ?? "Er du sikker?";
  public areYouSureUserDelete = () : string => this.translations.areyousureuserdelete ?? "Er du sikker på, at du vil slette brugeren? Dette kan ikke omgøres.";
  public areYouSureCaseDelete = () : string => this.translations.areyousurecasedelete ?? "Er du sikker på, at du vil slette sagen? Dette kan ikke omgøres.";
  public areYouSureDocumentDelete = () : string => this.translations.areyousuredocumentdelete ?? "Er du sikker på, at du vil slette dokumentet? Dette kan ikke omgøres.";
  public areYouSureDocumentDeleteExtractHint = () : string => this.translations.areyousuredocumentdeleteextracthint ?? "Dokumentet vil også blive slettet fra følgende ekstrakt-kladder";
  public areYouSureExtractDraftDelete = () : string => this.translations.areyousureextractdraftdelete ?? "Du er ved at slette en ekstraktkladde. Handlingen kan ikke fortrydes. Er du sikker på, at du vil fortsætte?";
  public areYouSurePresentationDelete = () : string => this.translations.areyousurepresentationdelete ?? "Er du sikker på, at du vil slette præsentationen? Dette kan ikke omgøres.";
  public areYouSurePresentationClose = () : string => this.translations.areyousurepresentationclose ?? "Er du sikker på, at du vil lukke præsentationen?";
  public employeeWasNotDeleted = () : string => this.translations.employeewasnotdeleted ?? "Der skete en fejl, og medarbejderen blev ikke slettet";
  public theUserprofileWasDeleted = () : string => this.translations.theuserprofilewasdeleted ?? "Brugerprofilen blev slettet";
  public dragFileHereFromCase = () : string => this.translations.dragfileherefromcase ?? "Træk filer her fra sagen";
  public referenceNumber = () : string => this.translations.referencenumber ?? "Referencenummer";
  public nr = () : string => this.translations.nr ?? "Nr.";

  public addDocuments = () : string => this.translations.adddocuments ?? "Tilføj dokument(er)";
  public missingVerticalLines = () : string => this.translations.missingverticallines ?? "dokumenter blev ikke tilføjet pga. manglende indstregninger.";
  public onlyIncludeVerticalLines = () : string => this.translations.onlyincludeverticallines ?? "Kun inkluder dokumenter med indstregninger";
  public uploadFiles = () : string => this.translations.uploadfiles ?? "Upload filer";
  public uploadFilesDescription1 = () : string => this.translations.uploadfilesdescription1 ?? "Her kan du tilføje dokumenter til sagen";
  public uploadFilesDescription2 = () : string => this.translations.uploadfilesdescription2 ?? "Så snart dokumenterne er indlæst, kan du finde dem på listen under \"Dokumenter\"";
  public uploadFilesHint1 = () : string => this.translations.uploadfileshint1 ?? "Vælg en eller flere filer, eller træk hertil";
  public uploadFilesHint2 = () : string => this.translations.uploadfileshint2 ?? "Dokumenterne skal være i PDF-format";
  public chooseFiles = () : string => this.translations.choosefiles ?? "Vælg filer";
  public chooseDocument = () : string => this.translations.choosedocument ?? "Vælg dokument";
  public choosePage = () : string => this.translations.choosepage ?? "Vælg side";
  public filesAdded = () : string => this.translations.filesadded ?? "Filer tilføjet";
  public finish = () : string => this.translations.finish ?? "Afslut";
  public locatePage = () : string => this.translations.locatepage ?? "Fremsøg side";
  public browsePage = () : string => this.translations.browsepage ?? "Fremsøg side";
  public documentType = () : string => this.translations.documenttype ?? "Dokumenttype";

  public pdfNotFound = () : string => this.translations.pdfnotfound ?? "Dokumentet findes ikke";
  public pdfDownloading = () : string => this.translations.pdfdownloading ?? "Henter dokument";
  public pdfToolbarSearch = () : string => this.translations.pdftoolbarsearch ?? "Indtast søgning";
  public pdfPage = () : string => this.translations.pdfpage ?? "Side";
  public pdfPages = () : string => this.translations.pdfpages ?? "Sider";
  public pdfTableOfContents = () : string => this.translations.tableofcontents ?? "Indholdsfortegnelse";
  public pdfZoom = () : string => this.translations.pdfzoom ?? "Zoom";
  public zoomIn = () : string => this.translations.zoomin ?? "Zoom ind";
  public zoomOut = () : string => this.translations.zoomout ?? "Zoom ud";
  public zoomWholePage = () : string => this.translations.zoomwholepage ?? "Hele siden";
  public zoomFitToWidth = () : string => this.translations.zoomfittowidth ?? "Tilpas bredde";
  public pdfCreatedByFilterTitle = () : string => this.translations.pdfcreatedbyfiltertitle ?? "Oprettet af";
  public pdfMarkingTypes = () : string => this.translations.pdfmarkingtypes ?? "Markeringstyper";

  public pdfToolbarOf = () : string => this.translations.pdftoolbarof ?? "af";
  public outOf = () : string => this.translations.outof ?? "ud af";
  public of = () : string => this.translations.of ?? "af";

  public userRights = () : string => this.translations.accessrights ?? "Brugerrettigheder";
  public defaultUserRightsLabel = () : string => this.translations.defaultuserrights ?? "Må tilføjes til sager";
  public defaultUserRightsDescription = () : string => this.translations.defaultuserrightsdescription ?? "Som bruger kan man altid blive tilføjet til sager";

  public systemAdministrator = () : string => this.translations.systemadministrator ?? "Systemadministrator";
  public canCreateCase = () : string => this.translations.cancreatecase ?? "Må oprette sager";
  public ownCase = () : string => this.translations.owncase ?? "Må eje sager";
  public administerOrgsAndUsers = () : string => this.translations.administerorgsandusers ?? "Må administrere organisation og brugere";
  public accessToAllCases = () : string => this.translations.accesstoallcases ?? "Har adgang til alle sager";

  public defaultCaseRights = () : string => this.translations.defaultcaserights ?? "Sagsrettigheder";
  public defaultCaseRightsLabel = () : string => this.translations.defaultcaserightslabel ?? "Må se og oprette dokumenter og markeringer";
  public defaultCaseRightsDescription = () : string => this.translations.defaultcaserightsdescription ?? "Når man er tilføjet til en sag kan man altid se og oprette dokumenter og markeringer";

  public caseAccessRightAddUsers = () : string => this.translations.caseaccessrightaddusers ?? "Må tilføje deltagere";
  public caseAccessRightDeleteDocuments = () : string => this.translations.caseaccessrightdeletedocuments ?? "Må slette dokumenter";
  public caseAccessRightEditDeleteAllMarkings = () : string => this.translations.caseaccessrighteditdeleteallmarkings ?? "Må redigere og slette markeringer og kommentarer";
  public caseAccessRightEditDeletePresentations = () : string => this.translations.caseaccessrighteditdeletepresentations ?? "Må redigere og slette præsentationer";
  public goBack = () : string => this.translations.goback ?? "Gå tilbage";
  public back = () : string => this.translations.back ?? "Tilbage";
  public ok = () : string => this.translations.ok ?? "Ok";

  public comment = () : string => this.translations.comment ?? "Kommentar";
  public comments = () : string => this.translations.comments ?? "Kommentarer";
  public commentRequired = () : string => this.translations.commentrequired ?? "Kommentar er påkrævet";
  public private = () : string => this.translations.private ?? "Privat";
  public privateComment = () : string => this.translations.privatecomment ?? "Privat kommentar";
  public tags = () : string => this.translations.tags ?? "Emne(r)";
  public tagsPlural = () : string => this.translations.tagsplural ?? "Emner";
  public createTags = () : string => this.translations.createtags ?? "Opret Emne(r)";
  public close = () : string => this.translations.close ?? "Luk";
  public closeWithoutSaving = () : string => this.translations.closewithoutsaving ?? "Luk uden at gemme";
  public saveAndClose = () : string => this.translations.saveandclose ?? "Gem og luk";

  public focusBoxTitle = () : string => this.translations.focusboxtitle ?? "Fokus boks";
  public verticalLinesTitle = () : string => this.translations.verticallinestitle ?? "Indstregning";
  public verticalLines = () : string => this.translations.verticallines ?? "M. indstregning";
  public highlightTitle = () : string => this.translations.highlighttitle ?? "Highlight";

  public presentation = () : string => this.translations.presentation ?? "Forelæggelse";
  public witnessExamination = () : string => this.translations.witnessexamination ?? "Vidneafhøring";
  public closingArguments = () : string => this.translations.closingarguments ?? "Procedure";

  public editDetails = () : string => this.translations.editdetails ?? "Rediger detaljer";
  public caseDetails = () : string => this.translations.casedetails ?? "Sagsdetaljer";
  public changeStatus = () : string => this.translations.changestatus ?? "Skift status";
  public areYouSureChangeCaseStatusTo = (newStatus: string) : string => `${this.translations.areyousurechangecasestatusto ?? "Er du sikker på, at du vil skifte sagens status til: "} ${newStatus}`;

  public deleteCase = () : string => this.translations.deletecase ?? "Slet sag";
  public theCaseWasDeleted = () : string => this.translations.thecasewasdeleted ?? "Sagen blev slettet";

  public open = () : string => this.translations.open ?? "Åben";
  public closed = () : string => this.translations.closed ?? "Lukket";
  public archived = () : string => this.translations.archived ?? "Arkiveret";

  public documentTitleRequired = () : string => this.translations.documenttitlerequired ?? "Dokumenttitel er påkrævet";

  public numberOfPages = () : string => this.translations.numberofpages ?? "Antal sider";
  public pagesIncludedInExtract = () : string => this.translations.pagesincludedinextract ?? "Sider inkluderet i ekstrakt";

  public tryAgain = () : string => this.translations.tryagain ?? "Prøv igen";

  public added = () : string => this.translations.added ?? "Tilføjet";
  public add = () : string => this.translations.add ?? "Tilføj";

  public showDrawer = () : string => this.translations.showdrawer ?? "Vis oversigtsskuffe";
  public noSpeakerNote = () : string => this.translations.nospeakernote ?? "Ingen talenote";
  public createSpeakerNote = () : string => this.translations.createspeakernote ?? "Opret talenote";
  public createAsSpeakerNote = () : string => this.translations.createasspeakernote ?? "Opret som talenote";
  public speakerNoteCreated = () : string => this.translations.speakernotecreated ?? "Talenote oprettet";
  public speakerNote = () : string => this.translations.speakernote ?? "Talenote";
  public speakerNotes = () : string => this.translations.speakernotes ?? "Talenoter";
  public editSpeakerNote = () : string => this.translations.editspeakernote ?? "Rediger talenote";
  public createSpeakerNoteInfo = () : string => this.translations.createspeakernoteinfo ?? "Her kan du oprette talenoter, som du selv kan se når du afspiller sekvensen.";
  public addToPresentation = () : string => this.translations.addtopresentation ?? "Tilføj til præsentation";
  public addToPresentations = () : string => this.translations.addtopresentations ?? "Tilføj til præsentation(er)";
  public selectPageAndPresentation = () : string => this.translations.selectpageandpresentation ?? "Vælg mindst én side og mindst én præsentation";
  public chooseMarkings = () : string => this.translations.choosemarkings ?? "Vælg markeringer";
  public showMarkings = () : string => this.translations.showmarkings ?? "Vis markeringer";
  public noMarkingsOnPage = () : string => this.translations.nomarkingsonpage ?? "Der er ingen markeringer på denne side. Gå til dokumentet for at oprette markeringer.";
  public thePageWasAddedToThePresentation = () : string => this.translations.thepagewasaddedtothepresentation ?? "Siden blev tilføjet til præsentation";
  public pagesAddedToPresentations = () : string => this.translations.pagesaddedtopresentations ?? "Siden blev tilføjet til præsentation";
  public noPagesInPresentation = () : string => this.translations.nopagesinpresentation ?? "Der er ingen sider tilføjet til præsentationen.";
  public removeFromPresentation = () : string => this.translations.removefrompresentation ?? "Fjern fra præsentationen";
  public goToPage = () : string => this.translations.gotopage ?? "Gå til side";
  public goToPageInDocument = () : string => this.translations.gotopageindocument ?? "Gå til side i dokumentet";
  public goToPageInPresentation = () : string => this.translations.gotopageinpresentation ?? "Gå til side i præsentationen";
  public goToDocument = () : string => this.translations.gotodocument ?? "Gå til dokument";
  public pageGotRemoved = () : string => this.translations.pagegotremoved ?? "Siden blev fjernet fra sekvensen";
  public deleteFile = () : string => this.translations.deletefile ?? "Slet fil";
  public presentationEditted = () : string => this.translations.presentationeditted ?? "Præsentationen blev redigeret";
  public orderChanged = () : string => this.translations.orderchanged ?? "Rækkefølgen blev ændret";
  public addPages = () : string => this.translations.addpages ?? "Tilføj side(r)";
  public addPagesToPresentations = () : string => this.translations.addpagestopresentations ?? "Tilføj sider til præsentationer";

  public me = () : string => this.translations.me ?? "Mig";
  public none = () : string => this.translations.none ?? "Ingen";
  public dropdownValuesCountSelectedSuffix = () : string => this.translations.dropdownvaluescountselectedsuffix ?? "valgte";

  public fileUploadError = (filename: string) : string => this.translations.fileuploaderror ? this.formatString(this.translations.fileuploaderror, filename) : `Der skete en fejl under upload af filen "${filename}"`;

  public allMarkings = () : string => this.translations.allmarkings ?? "Alle markeringer";
  public previousPage = () : string => this.translations.previouspage ?? "Forrige side";
  public nextPage = () : string => this.translations.nextpage ?? "Næste side";
  public showMore = () : string => this.translations.showmore ?? "Vis mere";
  public showLess = () : string => this.translations.showless ?? "Vis mindre";
  public play = () : string => this.translations.play ?? "Afspil";
  public exportAsPdf = () : string => this.translations.exportaspdf ?? "Eksportér som PDF";
  public approveExtractDraft = () : string => this.translations.approveextractdraft ?? "Færdiggør ekstrakt";

  public approveExtractDraftHint1 = () : string => this.translations.approveextractdrafthint1 ?? "Ekstraktkladden eksporteres som ét samlet dokument der vises på sagen under fanen Dokumenter. Der kan arbejdes videre med alle kommentarer, svartråde og markeringer i den færdige ekstrakt.";
  public approveExtractDraftHint2 = () : string => this.translations.approveextractdrafthint2 ?? "Ekstraktkladden slettes ikke - du kan arbejde videre med den efter behov.";
  public approveExtractDraftHint3 = () : string => this.translations.approveextractdrafthint3 ?? "Herunder kan du vælge hvilke brugeres kommentarer og markeringer der skal indgå i den færdige ekstrakt samt hvilke typer.";

  public shouldConvertColoredLinesToBlack = () : string => this.translations.shouldconvertcoloredlinestoblack ?? "Skal farvede indstregninger konverteres til sorte?";
  public shouldSplitExtractToBundles = () : string => this.translations.shouldsplitextracttobundles ?? "Skal ekstrakten opdeles i bind?";
  public extractDraftBundleInfo1 = () : string => this.translations.extractdraftbundleinfo1 ?? "Opdel ekstrakten i bind ved at angive antal af sider pr. bind.";
  public extractDraftBundleInfo2 = () : string => this.translations.extractdraftbundleinfo2 ?? "Dokumenter der indgår i alle bind (tæller ikke med i indholdsfortegnelsen)";
  public extractDraftBundleInfo3 = () : string => this.translations.extractdraftbundleinfo3 ?? "Antallet af sider skal være på eller over størrelsen af det største dokument, inklusive forsiden og indholdsfortegnelsen:";
  public bundle = () : string => this.translations.bundle ?? "Bind";
  public addBundle = () : string => this.translations.addbundle ?? "Tilføj bind";
  public yesConvertAllIncludedLinesToBlack = () : string => this.translations.yesconvertallincludedlinestoblack ?? "Ja, alle inkluderede indstregninger skal være sorte";
  public exportExtractDraftHint1 = () : string => this.translations.exportextractdrafthint1 ?? "Ekstrakt-kladden eksporteres som PDF.";
  public exportExtractDraftReadyHint1 = () : string => this.translations.exportextractdraftreadyhint1 ?? "Ekstrakten er nu eksporteret som PDF.";
  public completeExtractHint1 = () : string => this.translations.completeextracthint1 ?? "Vent venligst mens ekstrakten genereres.";
  public completeExtractHint2 = () : string => this.translations.completeextracthint2 ?? "Når ekstrakten er klar, kan den findes i dokument-listen eller via linket herunder.";
  public completeExtractReadyHint1 = () : string => this.translations.completeextractreadyhint1 ?? "Ekstrakten er nu genereret.";
  public completeExtractReadyHint2 = () : string => this.translations.completeextractreadyhint2 ?? "Den kan findes i dokument-listen eller via linket herunder.";
  public exportPresentation = () : string => this.translations.exportpresentation ?? "Eksportér præsentation";
  public exportPresentationHint1 = () : string => this.translations.exportpresentationhint1 ?? "Præsentationen eksporteres som PDF.";
  public exportPresentationHint2 = () : string => this.translations.exportpresentationhint2 ?? "Herunder kan du vælge hvilke brugeres kommentarer og markeringer der skal indgå i den eksporterede PDF, samt hvilke typer.";
  public markingOwnersTitle = () : string => this.translations.markingownerstitle ?? "Hvilke brugeres markeringer skal indgå i PDF'en?";
  public markingOwnersLabel = () : string => this.translations.markingownerslabel ?? "Filtrér markeringer";
  public markingTypesTitle = () : string => this.translations.markingtypestitle ?? "Hvilke typer markeringer skal indgå i PDF'en?";
  public exportVerb = () : string => this.translations.exportverb ?? "Eksportér";
  public exportCaseDocument = () : string => this.translations.exportcasedocument ?? "Eksportér dokument";
  public exportCaseDocumentHint1 = () : string => this.translations.exportcasedocumenthint1 ?? "Præsentationen eksporteres som PDF.";
  public exportCaseDocumentHint2 = () : string => this.translations.exportcasedocumenthint2 ?? "Herunder kan du vælge hvilke brugeres kommentarer og markeringer der skal indgå i den eksporterede PDF, samt hvilke typer.";
  public exportCaseDocumentDownloadHint1 = () : string => this.translations.exportcasedocumentdownloadhint1 ?? "Vent venligst mens PDF'en genereres.";
  public exportCaseDocumentDownloadHint2 = () : string => this.translations.exportcasedocumentdownloadhint2 ?? "Når den er klar kan du downloade den herunder.";
  public exportCaseDocumentDownloadReadyHint1 = () : string => this.translations.exportcasedocumentdownloadreadyhint1 ?? "Dokumentet er nu eksporteret som PDF.";
  public exportCaseDocumentDownloadReadyHint2 = () : string => this.translations.exportcasedocumentdownloadreadyhint2 ?? "Du kan download PDF'en herunder.";


  public download = () : string => this.translations.download ?? "Download";
  public exportPresentationDownloadHint1 = () : string => this.translations.exportpresentationdownloadhint1 ?? "Vent venligst mens PDF'en genereres.";
  public exportPresentationDownloadHint2 = () : string => this.translations.exportpresentationdownloadhint2 ?? "Når den er klar kan du downloade den herunder.";
  public exportPresentationDownloadReadyHint1 = () : string => this.translations.exportpresentationdownloadreadyhint1 ?? "Præsentationen er nu eksporteret som PDF.";
  public exportPresentationDownloadReadyHint2 = () : string => this.translations.exportpresentationdownloadreadyhint2 ?? "Du kan download PDF'en herunder.";
  public downloadPdf = () : string => this.translations.downloadpdf ?? "Download PDF";
  public onlyVisible = () : string => this.translations.onlyvisible ?? "Kun synlig for dig";

  public editDocument = () : string => this.translations.editdocument ?? "Rediger dokument";

  public nowPlaying = () : string => this.translations.nowplaying ?? "Igangværende afspilning";
  public resumePlayback = () : string => this.translations.resumeplayback ?? "Genoptag afspilning";

  public processingFile = () : string => this.translations.processingfile ?? "Behandler fil";

  public cantDeleteMarkingUsedInPresentation = () : string => this.translations.cantdeletemarkingusedinpresentation ?? "Markeringen kan ikke slettes da den bruges i én eller flere præsentationer";

  public markings = () : string => this.translations.markings ?? "Markeringer";
  public placesInContent = () : string => this.translations.placesincontent ?? "Steder i indhold";

  public placement = () : string => this.translations.placement ?? "Placering";
  public relevance = () : string => this.translations.relevance ?? "Relevans";

  public downloadApp = () : string => this.translations.downloadapp ?? "Download app";
  public playInApp = () : string => this.translations.playinapp ?? "Afspil i app";
  public platformNotSupported = () : string => this.translations.platformnotsupported ?? "Præsentations-app’en understøtter pt. kun windows";

  public searchAtLeast3Characters = () : string => this.translations.searchatleast3characters ?? "Søg (mindst 3 karakterer)";

  public presentationUpdated = () : string => this.translations.presentationupdated ?? "Sekvensen er opdateret";

  public unsavedChangesWarningTitle = () : string => this.translations.unsavedchangeswarningtitle ?? "Vil du annullere uden at gemme?";
  public unsavedChangesWarningText = () : string => this.translations.unsavedchangeswarningtext ?? "Hvis du lukker uden først at gemme, slettes dine ændringer";

  public preparePresentation = () : string => this.translations.preparepresentation ?? "Sekvens genereres";

  public startingConnection = () : string => this.translations.startingconnection ?? "Forbindelse oprettes";
  public connectionFailed = () : string => this.translations.connectionfailed ?? "Forbindelse kunne ikke oprettes.";
  public select = () : string => this.translations.select ?? "Interagér";
  public textSelect = () : string => this.translations.textselect ?? "Marker tekst";
  public theemployees = () : string => this.translations.theemployees ?? "Medarbejdere";
  public userAdministration = (): string => this.translations.useradministration ?? "Brugeradministration";

  public pagesFromDocument = () : string => this.translations.pagesfromdcument ?? "Side(r) fra dokument";
  public presentationTitlePage = () : string => this.translations.presentationtitlepage ?? "Skilleside";
  public isntIncludedInExtract = () : string => this.translations.isntincludedinextract ?? "Indgår ikke i ekstrakt";
  public createPresentationTitlePage = () : string => this.translations.createpresentationtitlepage ?? "Opret skilleside";
  public editPresentationTitlePage = () : string => this.translations.editpresentationtitlepage ?? "Rediger skilleside";

  public thePresentationTitlePageWasUpdated = () : string => this.translations.thepresentationtitlepagewasupdated ?? "Skillesiden blev opdateret";
  public createPresentationTitlePageHint1 = () : string => this.translations.createpresentationtitlepagehint1 ?? "Her kan du oprette en skilleside i præsentationen";
  public createPresentationTitlePageHint2 = () : string => this.translations.createpresentationtitlepagehint2 ?? "Du kan enten lade den være blank, eller udfylde teksterne herunder.";
  public header = () : string => this.translations.header ?? "Overskrift";
  public contentText = () : string => this.translations.contenttext ?? "Indholdstekst";
  public content = () : string => this.translations.content ?? "Indhold";

  public reply = () : string => this.translations.reply ?? "Svar";
  public replies = () : string => this.translations.replies ?? "Svar";
  public yourAnswer = () : string => this.translations.youranswer ?? "Dit svar";
  public edited = () : string => this.translations.edited ?? "redigeret";

  public frontpage = () : string => this.translations.frontpage ?? "Forside";
  public tableOfContents = () : string => this.translations.tableofcontents ?? "Indholdsfortegnelse";
  public showInTableOfContents = () : string => this.translations.showintableofcontents ?? "Vis i indholdsfortegnelse";
  public frontpageInfoHint1 = () : string => this.translations.frontpageinfohint1 ?? "Her kan du uploade et dokument til forsiden.";
  public frontpageInfoHint2 = () : string => this.translations.frontpageinfohint2 ?? "Dokumentet skal være i PDF format.";
  public frontpageUploadHint1 = () : string => this.translations.frontpageuploadhint1 ?? "Du kan kun uploade ét dokument til forsiden";

  public createExtractDraft = () : string => this.translations.createextractdraft ?? "Opret ekstrakt-kladde";
  public renameExtractDraft = () : string => this.translations.renameextractdraft ?? "Omdøb ekstrakt-kladde";
  public deleteExtractDraft = () : string => this.translations.deleteextractdraft ?? "Slet ekstrakt-kladde";
  public createExtractDraftHint1 = () : string => this.translations.createextractdrafthint1 ?? "Her kan du oprette en ekstrakt-kladde";
  public createExtractDraftHint2 = () : string => this.translations.createextractdrafthint2 ?? "Ekstrakt-kladder gør det muligt at samle flere dokumenter i ét.";
  public extractDraftTitlePlaceholder = () : string => this.translations.extractdrafttitleplaceholder ?? "Indtast kladdens titel";
  public extractDraftWasCreated = () : string => this.translations.extractdraftwascreated ?? "Ekstrakt-kladde blev oprettet";
  public extractDraftProcessingError = () : string => this.translations.extractdraftprocessingerror ?? "En fejl opstod under behandlingen af ekstrakt-kladden. Prøv igen senere, og kontakt supporten, hvis problemet fortsætter.";
  public caseExtract = () : string => this.translations.caseextract ?? "Sagens ekstrakt";

  public generateTableOfContents = () : string => this.translations.generatetableofcontents ?? "Generer indholdsfortegnelse";

  public showLeader = () : string => this.translations.showleader ?? "Forbind titel og sidetal";
  public addDotsFromTitleToPageNumber = () : string => this.translations.adddotsfromtitletopagenumber ?? "Punktummer fra titel til sidetal";
  public showDateColumn = () : string => this.translations.showdatecolumn ?? "Vis datokolonne";
  public showAppendixNumberColumn = () : string => this.translations.showappendixnumbercolumn ?? "Vis bilagsnummer kolonne";
  public showColumnsOnAllPages = () : string => this.translations.showcolumnsonallpages ?? "Vis overskrifter på alle sider";
  public documentsStartOnOddPages = () : string => this.translations.documentsstartonoddpages ?? "Start dokumenter på ulige sider";
  public alternatingPageNumbers = () : string => this.translations.alternatingpagenumbers ?? "Sidetal skifter side";
  public tableOfContentsShowPageNumber = () : string => this.translations.tableofcontentsshowpagenumber ?? "Vis sidetal på indholdsfortegnelse";
  public frontpageShowPageNumber = () : string => this.translations.frontpageshowpagenumber ?? "Vis sidetal på forsiden";
  public insertSectionDivider = () : string => this.translations.insertsectiondivider ?? "Indsæt skilleside for sektioner";
  public showPageNumbersAsIntervals = () : string => this.translations.showpagenumbersasintervals ?? "Vis sidetal som interval";
  public showLocalPageNumber = () : string => this.translations.showlocalpagenumber ?? "Vis lokalt sidetal";
  public linkTitleAndPageNumber = () : string => this.translations.linktitleandpagenumber ?? "Forbind titel og sidetal";
  public pageNumber = () : string => this.translations.pagenumber ?? "Sidetal";
  public applyPageNumbers = () : string => this.translations.applypagenumbers ?? "Påfør sidetal";
  public uniquePosition = () : string => this.translations.uniqueposition ?? "Unik placering";
  public addToDraft = () : string => this.translations.addtodraft ?? "Tilføj til kladde";
  public removeFromDraft = () : string => this.translations.removefromdraft ?? "Fjern fra kladde";
  public removeFromExtract = () : string => this.translations.removefromextract ?? "Fjern fra ekstrakt";
  public includeMarkingsOnExport = () : string => this.translations.includemarkingsonexport ?? "Inkludér markeringer ved eksport";
  public includeMarkings = () : string => this.translations.includemarkings ?? "Inkludér markeringer";
  public includeSpeechNotes = () : string => this.translations.includespeechnotes ?? "Inkludér talenoter";
  public includedPages = () : string => this.translations.includedpages ?? "Inkluderede sider";
  public addDocumentsToDraftHint1 = () : string => this.translations.adddocumentstodrafthint1 ?? "Kladden er nu oprettet, og kan ses på dokumentlisten.";
  public addDocumentsToDraftHint2 = () : string => this.translations.adddocumentstodrafthint2 ?? "Du kan herunder tilføje hele eller dele af dokumenter til kladden.";
  public addDocumentsToDraftHint3 = () : string => this.translations.adddocumentstodrafthint3 ?? "Dette kan også gøres og justeres fra dokumentlisten senere.";
  public addDocumentsToDraftNoDocumentsFoundHint = () : string => this.translations.adddocumentstodraftnodocumentsfoundhint ?? "Der kunne ikke findes nogen dokumenter på sagen. Du kan tilføje dokumenter til kladden senere, når dokumenter er uploadet til sagen.";
  public extractDraftDocumentsWasAdded = () : string => this.translations.extractdraftdocumentswasadded ?? "Dokumenterne blev tilføjet til kladden";
  public pagesWithVerticalLines = () : string => this.translations.pageswithverticallines ?? "Vælg automatisk forsiden samt sider som har indstregning";
  public pages = () : string => this.translations.pages ?? "Sider";
  public page = () : string => this.translations.page ?? "Side";
  public pagesWithVerticalLines1 = () : string => this.translations.pageswithverticallines1 ?? "Sider med indstregning";
  public verticalLinesInfoButton = () : string => this.translations.verticallinesinfobutton ?? "Når du tilføjer dokumenter med denne indstilling slået til, inkluderes kun sider med lodrette linjer.";

  public pageIntervalIsInvalid = (): string => this.translations.pageintervalisinvalid ?? "Intervallet er ugyldigt";
  public pageIntervalOutOfRangeMax = (maxNumber: string): string => this.translations.pageintervaloutofrangemax ? this.formatString(this.translations.pageintervaloutofrangemax, maxNumber) : `Intervallet er ugyldigt (maks.: ${maxNumber})`;
  public pageIntervalOutOfRangeMin = (minNumber: string): string => this.translations.pageintervaloutofrangemin ? this.formatString(this.translations.pageintervaloutofrangemin, minNumber) : `Intervallet er ugyldigt (min.: ${minNumber})`;
  public intervals = (): string => this.translations.intervals ?? "Interval(er)";
  public definePageIntervals = (): string => this.translations.definepageintervals ?? "Definér sideintervaller";
  public pageIntervalInfoMessage = (): string => this.translations.pageintervalinfomessage ?? "Angiv intervaller ved for eksempel at skrive '1-3, 5-10' (i dette eksempel inkluderes siderne 1 til 3 og siderne 5 til 10).";
  public morePagesToPresentations = (): string => this.translations.morepagestopresentations ?? "Flere sider til præsentationer";

  public mention = () : string => this.translations.Mention ?? "Nævn";

  public undo = () : string => this.translations.undo ?? "Undo";
  public redo = () : string => this.translations.redo ?? "Redo";
  public yes = () : string => this.translations.yes ?? "Ja";
  public no = () : string => this.translations.no ?? "Nej";

  public revertToOriginal = () : string => this.translations.reverttooriginal ?? "Gendan til original";
  public fieldChangedOriginalIs = () : string => this.translations.fieldchangedoriginalis ?? "Dette felt er blevet ændret. Det oprindelige navn var";

  public insertTitlePage = () : string => this.translations.inserttitlepage ?? "Indsæt skilleside";

  public selectAll = () : string => this.translations.selectall ?? "Vælg alle";
  public selectNone = () : string => this.translations.selectnone ?? "Vælg ingen";

  public presentPage = () : string => this.translations.presentpage ?? "Præsentér side";

  public temporaryDisplay = () : string => this.translations.temporarydisplay ?? "Midlertidig visning";
  public chosenPageInsteadOfPresentation = () : string => this.translations.chosenpageinsteadofpresentation ?? "Den valgte side vises nu i stedet for præsentationen.";
  public backToPresentation = () : string => this.translations.backtopresentation ?? "Tilbage til præsentationen";
  public showNewPage = () : string => this.translations.shownewpage ?? "Vis ny side";

  public cantRotatePageWhenMarkings = () : string => this.translations.cantrotatepagewhenmarkings ?? "Siden kan ikke roteres da den indeholder markeringer";
  public turnOrientation = () : string => this.translations.turnorientation ?? "Vend orientering";

  public noMarkingsRotationMismatch = () : string => this.translations.nomarkingsrotationmismatch ?? "Det er ikke muligt at tilføje markeringer, da siden er tilføjet til en præsentation, hvor den har en anderledes rotation";

  public saveForOfflineUse = () : string => this.translations.saveforofflineuse ?? "Gem til offline brug";
  public savePresentation = () : string => this.translations.savepresentation ?? "Gem præsentation";
  public presentationSaved = () : string => this.translations.presentationsaved ?? "Præsentation gemt til offline brug";
  public presentationSaveHint = () : string => this.translations.presentationsavehint ?? "Gem præsentationsdata så præsentationen kan tilgås og præsenteres uden internetforbindelse.";
  public presentationSaveHint2 = () : string => this.translations.presentationsavehint2 ?? "Kun den nuværende version af præsentationen gemmes. Sker der ændringer til præsentationen skal den gemmes igen.";
  public presentationSaveDisabledHint = () : string => this.translations.presentationsavedisabledhint ?? "Præsentationer kan kun gemmes til offline brug i appen";
  public presentationSaveError = () : string => this.translations.presentationsaveerror ?? "Der opstod en uventet fejl. Venligst opdater siden og prøv igen.";
  public presentationLastSave = () : string => this.translations.presentationlastsave ?? "Sidst gemt til offline brug";
}
