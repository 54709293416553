import classNames from "classnames";
import { PointerEvent, ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { Document } from "react-pdf";
import { DocumentCallback } from "react-pdf/dist/cjs/shared/types";
import { useLocalization } from "@components/localization/localizationProvider";
import { Dimensions } from "@components/resizable/resizable";
import { Spinner } from "@components/spinner/spinner";
import { pdfCommentLaneSpaceWidth } from "@pages/pdfviewer/component/page/canvas/canvasService";
import PageRenderer from "@pages/pdfviewer/component/page/pageRenderer";
import { PageDimensions } from "@pages/pdfviewer/component/pageDimensions";
import useElementSize from "src/hooks/useElementSize";
import { PageOrientation } from "@services/api/document/models/rotateCaseDocumentModel";
import styles from "./singlePagePdfView.module.scss";
import ZoomedPdfContainer from "./zoomedPdfContainer";
import { MarkingModel } from "./models/markingModel";
import { TempMarkingAction } from "./models/tempMarkingAction";
import usePageMarkings from "./page/canvas/usePageMarkings";
import getPageDimensions from "./utils/getPageDimensions";

export interface Position {
  x: number;
  y: number;
}

interface SinglePagePdfViewProps {
  pdfUrl: string;
  docPageIndex?: number;
  pdfPageIndex?: number;
  hideCards?: boolean;
  disableEditing?: boolean;
  hidePresentationBtn?: boolean;
  presentedMarkings?: string[];
  zoomArea?: Dimensions;
  orientation?: PageOrientation;
  onPresentationMarkingsChange?: (markingIds: string[]) => void;
  pageHeader?: ReactNode; // header slot that will be aligned with page even when cards are shown
  pageOverlay?: (scale: number) => ReactNode; // overlay of exact page
  pageMargins?: number;
  pageClassName?: string;
  onDocumentLoadSuccess?: (pdf: DocumentCallback) => void;
  onPointerPositionChange?: (p?: Position) => void,
  tempMarkings?: MarkingModel[];
  onTempMarkingAction?: (a: TempMarkingAction) => void;
  showMaxSize?: boolean;
  isLoadingPresentationMarkingsChange?: boolean;
}

const SinglePagePdfView = ({
  pdfUrl,
  pdfPageIndex = 0,
  docPageIndex = pdfPageIndex,
  hideCards,
  disableEditing,
  hidePresentationBtn,
  presentedMarkings,
  zoomArea,
  orientation,
  onPresentationMarkingsChange,
  pageHeader,
  pageOverlay,
  pageMargins = 0,
  pageClassName,
  onDocumentLoadSuccess,
  onPointerPositionChange,
  tempMarkings,
  onTempMarkingAction,
  showMaxSize,
  isLoadingPresentationMarkingsChange,
}: SinglePagePdfViewProps) => {
  const localizer = useLocalization();
  const [pdf, setPdf] = useState<DocumentCallback>();
  const [originalDimensions, setOriginalDimensions] = useState<PageDimensions>();
  const [containerRef, containerSize] = useElementSize();
  const wrapperRef = useRef<HTMLDivElement>(null);

  // delay rendering new pdf after previous one has finished to avoid pdfjs crash
  const [renderedPdf, setRenderedPdf] = useState<string>();
  const [isRendering, setIsRendering] = useState<boolean>(false);
  const showSpinner = isRendering || pdfUrl !== renderedPdf;
  useEffect(() => {
    if (pdfUrl !== renderedPdf && !isRendering) {
      setIsRendering(true);
      setRenderedPdf(pdfUrl);
    }
  }, [pdfUrl, isRendering, renderedPdf]);

  const handleLoadSuccess = (pdfProxy: DocumentCallback) => {
    onDocumentLoadSuccess?.(pdfProxy);
    setPdf(pdfProxy);
  };

  useEffect(() => {
    let skip = false;
    setOriginalDimensions(undefined);
    if (pdf && pdfPageIndex < pdf.numPages) {

      try {
        pdf.getPage(pdfPageIndex + 1)
          .then((page) => {
            if (skip || !page) {
              return;
            }
            const dimensions = getPageDimensions(page, orientation ?? 0);
            setOriginalDimensions({
              width: dimensions.width,
              height: dimensions.height,
              rotation: dimensions.rotation,
            });
          });
      } catch (error) {
        // we intentionally don't handle this since it's to catch the above promise throwing an error if the pdf gets changed while running the promise.
        // see: https://github.com/wojtekmaj/react-pdf/issues/974#issuecomment-1485767769
      }

      return () => { skip = true; };
    }
  }, [orientation, pdf, pdfPageIndex]); // adding dependencies to this array whose change also triggers refetches (queries) can cause crashes!

  const scale = useMemo(() => {
    if (!originalDimensions || !containerSize.width || !containerSize.height) {
      return;
    }

    // fit zoom area (limit to 4 times screen size to avoid pdfjs rendering issues) or whole page if nothing zoomed
    const zoomLimit = 10;
    const fitDimensions = {
      width: (zoomArea && Math.max(zoomArea.width, containerSize.width / zoomLimit)) || originalDimensions.width,
      height: (zoomArea && Math.max(zoomArea.height, containerSize.height / zoomLimit)) || originalDimensions.height,
    };

    // calculate scale that fully fits both width and height
    return Math.min(
      (containerSize.width - pageMargins * 2) / fitDimensions.width,
      (containerSize.height - pageMargins * 2) / fitDimensions.height,
    );
  }, [containerSize.height, containerSize.width, originalDimensions, pageMargins, zoomArea]);

  const overlayElement = scale && pageOverlay?.(scale);

  const handlePointerMove = (e: PointerEvent) => {
    if (onPointerPositionChange && wrapperRef.current && scale) {
      onPointerPositionChange({
        x: (e.clientX - wrapperRef.current.getBoundingClientRect().left) / scale,
        y: (e.clientY - wrapperRef.current.getBoundingClientRect().top) / scale,
      });
    }
  };

  const handlePointerLeave = (e: PointerEvent) => {
    if (onPointerPositionChange) {
      onPointerPositionChange(undefined);
    }
  };

  const { markings: allPageMarkings } = usePageMarkings(pdfPageIndex);
  const pageHasMarkings = allPageMarkings.length > 0;

  return (
    <div
      ref={containerRef}
      className={classNames("w-100 h-100 d-flex flex-column align-items-center relative", { "overflow-hidden": !showMaxSize })}
      style={{ paddingLeft: (!hideCards && pageHasMarkings) ? pdfCommentLaneSpaceWidth + window.innerWidth / 4 : 0 }}
      onPointerMove={handlePointerMove}
      onPointerLeave={handlePointerLeave}
    >
      {!zoomArea && pageHeader &&
        <div
          className={styles.pageHeader}
          style={{ width: originalDimensions && scale ? originalDimensions.width * scale : undefined }}
        >
          {pageHeader}
        </div>
      }
      <ZoomedPdfContainer
        zoomArea={zoomArea}
        scale={scale}
        containerSize={containerSize}
        className={styles.pdfWrapper}
        ref={wrapperRef}
      >
        <Document
          error={
            <h2 className="d-flex justify-content-center">
              {localizer.pdfNotFound()}
            </h2>
          }
          loading=""
          file={renderedPdf}
          onLoadSuccess={handleLoadSuccess}
        >
          {originalDimensions && scale &&
            <PageRenderer
              className={classNames(pageClassName, showSpinner && "d-none")}
              pageIndex={docPageIndex}
              pdfPageIndex={pdfPageIndex}
              data={{ scale }}
              pageDimensions={{
                width:  showMaxSize ? containerSize.width - pageMargins * 2 : originalDimensions.width * scale,
                height: showMaxSize ? containerSize.height - pageMargins * 2 : originalDimensions.height * scale,
                rotation: originalDimensions.rotation,
              }}
              hideCards={hideCards}
              disableEditing={disableEditing}
              hidePresentationBtn={hidePresentationBtn}
              selectedMarkings={presentedMarkings}
              onSelectedMarkingsChange={onPresentationMarkingsChange}
              onRenderSuccess={() => setIsRendering(false)}
              onRenderError={() => setIsRendering(false)}
              tempMarkings={tempMarkings}
              onTempMarkingAction={onTempMarkingAction}
              isLoadingMarkingsEdit={isLoadingPresentationMarkingsChange}
            />
          }
          {overlayElement &&
            <div className={styles.pageOverlay}>
              {overlayElement}
            </div>
          }
        </Document>
        {showSpinner &&
          <div className={classNames(styles.loadingSpinner, "d-flex justify-content-center")}>
            <Spinner />
          </div>
        }
      </ZoomedPdfContainer>
    </div>
  );
};

export default SinglePagePdfView;
