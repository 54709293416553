import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { UserService } from "@services/userService";
import { IHubConnectionManager } from "./IHubConnectionManager";

export abstract class HubConnectionManager implements IHubConnectionManager {
  private connections: Partial<Record<string, HubConnection>> = {};
  abstract apiUrl: string;
  abstract endpointName: string;
  abstract userService: UserService;
  private groupSubscribe = "GroupSubscribe";
  private groupUnsubscribe = "GroupUnsubscribe";

  private hubUrl = () => `${this.apiUrl}/${this.endpointName}`;


  getSignalRHeader = (groupId: string) => {
    // add signalR connection id to avoid getting back events that were already processed locally
    return { signalRConnectionId: this.getConnectionId(groupId) };
  };

  async startConnection(groupId: string) : Promise<void> {
    if (this.connections[groupId]) {
      return;
    }

    const connection =  new HubConnectionBuilder()
      .withUrl(
        this.hubUrl(),
        {
          withCredentials: false,
          accessTokenFactory: async () => await this.userService.getUserToken() ?? "",
        },
      )
      .build();

    this.connections[groupId] = connection;

    await connection.start();
    await connection.invoke(this.groupSubscribe, groupId);
  }

  on(groupId: string, method: string, listener: (...args: any[]) => void) {
    this.connections[groupId]?.on(method, listener);
  }

  off(groupId: string, methodName: string, method: (...args: any[]) => void) {
    this.connections[groupId]?.off(methodName, method);
  }

  getConnectionId(groupId: string) {
    return this.connections[groupId]?.connectionId ?? undefined;
  }

  async stopConnection(groupId: string) {
    const connection = this.connections[groupId];

    if (connection !== undefined) {
      delete this.connections[groupId];
      await connection.invoke(this.groupUnsubscribe, groupId);
      await connection.stop();
    }
  }

  async unsubscribe(groupId: string) {
    const connection = this.connections[groupId];
    await connection?.invoke(this.groupUnsubscribe, groupId);
  }
}
