import classNames from "classnames";
import { useLocalization } from "@components/localization/localizationProvider";
import { ExtractDraftDocumentPageIntervalModel } from "@services/api/extractDraft/models/extractDraftDocumentPageIntervalModel";
import { StatusType } from "@components/embla/statusIcon";
import styles from "./PageIntervals.module.scss";
import ExtractDocumentInterval from "./documentInterval/extractDocumentInterval";

type PageIntervalsProps = {
  pageIntervals: ExtractDraftDocumentPageIntervalModel[],
  caseId: string,
  caseDocumentId: string,
  status? : StatusType
};

const PageIntervals = ({ caseId, caseDocumentId, pageIntervals, status }:PageIntervalsProps) => {
  const localizer = useLocalization();
  
  return (
    <div className={styles.container}>
      <h4>{localizer.includedPages()}</h4>
      <div className={classNames("d-flex flex-wrap margin-top-m", styles.pageIntervalContainer)}>
        {pageIntervals.map((x, index) => (
          <ExtractDocumentInterval key={index} caseId={caseId} caseDocumentId={caseDocumentId} pageInterval={x} additionalClasses="margin-right-l" status={status}/>
        ))}
      </div>
    </div>
  );
};

export default PageIntervals;