import { useMarkingsPageContext } from "@pages/case/presentations/editPresentationPages/MarkingsPageContext/MarkingsPageContext";
import { useVirtualClickOutside } from "@pages/pdfviewer/component/hooks/useVirtualClickOutside";
import { createMarkingCardId } from "../../shared/canvasMarkingCard";
import useNewSafeMarkingClose from "./useNewSafeMarkingsClose";

const useNewMarkingClickOutside = () => {
  const { activeMarking } = useMarkingsPageContext();
  const { closeCard } = useNewSafeMarkingClose();

  useVirtualClickOutside(
    activeMarking?.marking && createMarkingCardId(activeMarking.marking),
    closeCard,
  );

};

export default useNewMarkingClickOutside;