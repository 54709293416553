import * as yup from "yup";
import { IntervalExtractionType, pageIntervalRegexString } from "@components/case/extractDraft/addDocumentsToDraftModel";
import { createValidationSchema } from "@components/validation/createValidationSchema";
import { DateTypeEnum } from "@services/api/case/models/dateTypeEnum";

export type ExtractDraftSectionDocumentUpdateModel = {
  caseDocumentId: string;
  id: string;
  title: string;
  includeMarkings: boolean;
  caseReferenceNumber?: string;
  date: string;
  dateType: DateTypeEnum;
  includeInToC: boolean;
  intervalExtractionType: IntervalExtractionType;
  pageIntervals: string;
};

export const draftSectionDocumentUpdateValidation = () => createValidationSchema<ExtractDraftSectionDocumentUpdateModel>({
  caseReferenceNumber: yup.string(),
  date: yup.string().nullable(),
  id: yup.string().required(),
  includeInToC: yup.boolean(),
  includeMarkings: yup.boolean(),
  intervalExtractionType: yup.mixed<IntervalExtractionType>().oneOf(Object.values(IntervalExtractionType) as number[]).required(),
  caseDocumentId: yup.string().required(),
  title: yup.string(),
  includePagesWithVerticalLines: yup.boolean(),
  pageIntervals: yup.string().matches(RegExp(pageIntervalRegexString), "e.g. \"1-3, 20, 24-50\""),
  dateType: yup.mixed<DateTypeEnum>().oneOf(Object.values(DateTypeEnum) as number[]).required(),
});
