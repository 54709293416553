import classNames from "classnames";
import { forwardRef, Ref } from "react";
import { useDispatch } from "react-redux";
import Resizable, { Dimensions, DimensionsInput, ResizableProps } from "@components/resizable/resizable";
import { FocusBoxMarkingModel, VerticalLinesMarkingModel } from "@pages/pdfviewer/component/models/markingModel";
import useForwardedRef from "src/hooks/useForwardedRef";
import { useMarkingsPageContext } from "@pages/case/presentations/editPresentationPages/MarkingsPageContext/MarkingsPageContext";
import { MarkingActiveStateType } from "@pages/pdfviewer/component/models/activeMarkingInfo";
import { removeActiveMarking, setActiveMarking } from "@pages/pdfviewer/component/pdfViewerSlice";
import useMarkingMutation from "@pages/pdfviewer/component/hooks/useMarkingMutation";
import useNewMarkingDnd from "../../hooks/useNewMarkingDnd/useNewMarkingDnd";

interface ResizableMarkingProps<T extends FocusBoxMarkingModel<false> | VerticalLinesMarkingModel<false>> extends Omit<ResizableProps, "onResize" | "dimensions"> {
  marking: T;
  scale: number;
}

const NewResizableMarking = forwardRef(<T extends FocusBoxMarkingModel<false> | VerticalLinesMarkingModel<false>>(
  {
    marking,
    scale,
    className,
    ...resizableProps
  }: ResizableMarkingProps<T>,
  forwardedRef: Ref<HTMLDivElement>,
) => {
  const dispatch = useDispatch();

  const { editMarking, createMarking } = useMarkingMutation();
  const { setActiveMarking:setActiveMarkingForContext } = useMarkingsPageContext();

  const markingElRef = useForwardedRef(forwardedRef);
  const { markingClassName, markingPosition, onPointerDown, dropBoundaryEl } = useNewMarkingDnd({
    marking,
    scale,
    canDrag: resizableProps.active,
    markingElRef,
  });

  const markingDimensions: DimensionsInput = {
    ...markingPosition,
    width: marking.data.width && marking.data.width * scale,
    height: marking.data.height && marking.data.height * scale,
  };

  const handleResize = (newDimensions: Dimensions) => {
    if (newDimensions.height === 0 || newDimensions.width === 0) {
      setActiveMarkingForContext(undefined);
      dispatch(removeActiveMarking());
      return;
    }

    const newMarking: T = {
      ...marking,
      x: newDimensions.x / scale,
      y: newDimensions.y / scale,
      data: {
        ...marking.data,
        width: newDimensions.width / scale,
        height: newDimensions.height / scale,
      },
    };
    if (marking.isNew) {
      setActiveMarkingForContext(undefined);
      dispatch(removeActiveMarking());
      createMarking(newMarking);
    } else {
      dispatch(setActiveMarking({
        marking: newMarking,
      }));
      setActiveMarkingForContext({
        activeState: MarkingActiveStateType.Edit,
        marking,
      });
      editMarking(newMarking);
    }
  };

  return <>
    <Resizable
      ref={markingElRef}
      onResize={handleResize}
      dimensions={markingDimensions}
      className={classNames(markingClassName, className)}
      onPointerDown={onPointerDown}
      {...resizableProps}
    />
    {dropBoundaryEl}
  </>;
});

export default NewResizableMarking;
