import classNames from "classnames";
import ReactDOM from "react-dom";
import { SingleValue } from "react-select";
import { RefObject, useRef } from "react";
import { usePopper } from "react-popper";
import InlineSwitch from "@components/checkbox/inlineSwitch";
import { Dropdown } from "@components/dropdown/dropdown";
import Card from "@components/embla/card";
import { useLocalization } from "@components/localization/localizationProvider";
import { useChangeIsContrastingColorMutation, useChangeIsPageNumberCoordinatesAutonomousMutation, useChangePageNumberFontMutation, useRotatePageNumberMutation } from "@services/api/document/caseDocumentApi";
import { PageNumberModel } from "@services/api/document/models/documentPageInfoModel";
import { ChangeIsContrastingColorModel, ChangeIsCoordinateAutonomousModel, PageNumberFontUpdateModel } from "@services/api/document/models/pageNumber";
import { getPageNumberFontSelectValues, PageNumberFontEnum } from "@services/api/extractDraft/models/extractDraftTableOfContentsModel";
import { useOnClickOutside } from "@pages/pdfviewer/component/hooks/useClickOutside";
import Button from "@components/embla/button";
import { ReactComponent as FlipIcon } from "@content/icons/flip-down.svg";
import { PageNumberOrientations, RotatePageNumberModel } from "@services/api/document/models/rotatePageNumberModel";
import styles from "./PageNumberEditingModal.module.scss";

type PageNumberEditingModalProps = {
  documentId: string;
  caseId: string;
  isVisible: boolean;
  font?: PageNumberFontEnum;
  numberData: PageNumberModel;
  numberElRef: RefObject<HTMLDivElement>;
  onClose?: () => void;
};

const PageNumberEditingModal = ({ documentId, caseId, isVisible, font, numberData, numberElRef, onClose } : PageNumberEditingModalProps) => {
  const localizer = useLocalization();

  const popoverRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(popoverRef, () => onClose?.());

  const [ changeFont ] = useChangePageNumberFontMutation();
  const [ changeIsAutonomousCoordinate ] = useChangeIsPageNumberCoordinatesAutonomousMutation();
  const [ changeIsContrastingColor ] = useChangeIsContrastingColorMutation();
  const [ rotatePageNumber ] = useRotatePageNumberMutation();

  const { styles: popperStyles, attributes } = usePopper(numberElRef.current, popoverRef.current, {
    placement: "bottom-start",
    strategy: "absolute",
  });

  const handleFontChange = (value: SingleValue<{ label: string; value: PageNumberFontEnum; }>) => {
    const model: PageNumberFontUpdateModel = {
      caseDocumentId: documentId,
      font: value!.value,
    };
    
    changeFont({ caseId, model });
  };

  const handleContrastingColorSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    const model: ChangeIsContrastingColorModel = {
      caseDocumentId: documentId,
      pageIndex: numberData.number - 1,
      isContrastColoring: checked,
    };
    changeIsContrastingColor({ caseId, model });
  };

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    const model: ChangeIsCoordinateAutonomousModel = {
      caseDocumentId: documentId,
      isAutonomousCoordinates: checked,
      number: numberData.number,
    };
    changeIsAutonomousCoordinate({ caseId: caseId, model });
  };
  
  const handleRotatePageNumber = () => {
    const newRotation = (numberData.orientation + 90) % 360 as PageNumberOrientations;

    const model: RotatePageNumberModel = {
      caseDocumentId: documentId,
      number: numberData.number,
      orientation: newRotation,
    };
    rotatePageNumber({ caseId, model });
  };


  return ReactDOM.createPortal(
    <Card
      className={classNames(styles.popover, "p-2 gap-8")}
      ref={popoverRef}
      style={{
        ...popperStyles.popper,
        visibility: isVisible ? "visible" : "hidden",
      }}
      {...attributes.popper}
    >
      {isVisible && (
        <>
          {
            font && (
              <div className="d-flex align-items-center justify-content-between gap-8">
                <span>{localizer.fontFamily()}</span>
                <Dropdown
                  className={styles.dropdown}
                  options={getPageNumberFontSelectValues()}
                  defaultValue={{ label: PageNumberFontEnum[font], value: font }}
                  onChange={handleFontChange}
                />
              </div>
            )
          }
          <div className="d-flex justify-content-between align-items-center">
            <span>{localizer.uniquePosition()}</span>
            <InlineSwitch
              checked={numberData.isAutonomousCoordinates}
              onChange={handleSwitchChange}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <span>{localizer.whiteBackground()}</span>
            <InlineSwitch onChange={handleContrastingColorSwitchChange} checked={numberData.isContrastColoring} />
          </div>
          <Button className="mt-2" onClick={handleRotatePageNumber}>
            <FlipIcon className={styles.iconColor} />
            {localizer.turnOrientation()}
          </Button>
        </>
      )}
    </Card>,
    document.body,
  );

};
export default PageNumberEditingModal;