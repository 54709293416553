import { DateFormatEnum } from "./dateFormatEnum";

export enum PageNumberFontEnum {
  Calibri = 1,
  Arial = 2,
  "Times New Roman" = 3,
}

export type ExtractDraftTableOfContentsModel = {
  id: string;
  generate: boolean;
  showDates: boolean;
  showOwnPageNumber: boolean;
  insertSectionDividers: boolean;
  showLeader: boolean;
  showPageNumbersAsIntervals: boolean;
  dateFormatEnum: DateFormatEnum;
  repeatableColumns: boolean;
  documentsStartOnOddPages: boolean;
  alternatingPageNumbers: boolean;
  showPageNumbers: boolean;
  showExhibit: boolean;
  font: PageNumberFontEnum;
};

export const getPageNumberFontSelectValues = () => {
  return Object.entries(PageNumberFontEnum)
    .filter(([key]) => isNaN(Number(key)))
    .map(([key, value]) => ({
      label: key,
      value: value as PageNumberFontEnum,
    }));
};
