import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "@hooks";
import { useGetDocumentPagesQuery } from "@services/api/document/caseDocumentApi";
import {
  convertMarkingTypesToOverlayIcons,
  DocumentPageOverlayIconTypeEnum,
} from "../models/documentPageOverlayIconType";
import { pdfTypeIdSelector } from "../pdfViewerSlice";
import usePageMarkings from "./canvas/usePageMarkings";

const usePageThumbnailIcons = (pageIndex: number, skipFetch?: boolean) => {
  const documentId = useAppSelector(pdfTypeIdSelector);
  const { caseId } = useParams();
  const { markings, isLoading: markingsIsLoading } = usePageMarkings(pageIndex);
  const { data: pages, isLoading: pagesAreLoading } = useGetDocumentPagesQuery(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    { caseId: caseId!, documentId: documentId! },
    { skip: skipFetch },
  );

  const overlayIcons = useMemo(() => {
    if (skipFetch) {
      return [];
    }

    const page = pages?.find((x) => x.pageIndex === pageIndex);
    return convertMarkingTypesToOverlayIcons(markings.map((x) => x.type), page?.hasPresentation);
  }, [markings, pageIndex, pages, skipFetch]);

  return {
    thumbnailOverlayIcons: overlayIcons,
    isLoading: pagesAreLoading || markingsIsLoading,
  };
};

export default usePageThumbnailIcons;
