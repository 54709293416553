import { ListChildComponentProps } from "react-window";
import { CSSProperties } from "react";
import { useDrag } from "react-dnd";
import { PageOrientation } from "@services/api/document/models/rotateCaseDocumentModel";
import { PageDimensionsArray } from "../../pageDimensions";
import NewPageRenderer from "../NewPageRenderer/NewPageRenderer";

export interface PageListChildData {
  getPageClassName?: (pageIndex: number) => string | undefined;
  isThumbnail?: boolean;
  pdfDimensions: PageDimensionsArray;
  scale?: number;
  renderAnnotationLayer?: boolean;
  visiblePages: number[];
  pageMargin: number;
  pageOrientations?: PageOrientation[];
  onDocumentLoaded?: () => void;
  documentId: string;
  draggable?: boolean;
}

const PageList = ({
  data,
  index,
  style,
  isScrolling,
}: ListChildComponentProps<PageListChildData>) => {
  const {
    documentId,
    pageMargin,
    pdfDimensions,
    onDocumentLoaded,
    pageOrientations,
    draggable = false,
  } = data;

  const pageDimensions = pdfDimensions[index];

  const listStyle: CSSProperties = {
    ...style,
    position: "absolute",
    width: pageDimensions.width,
    height: pageDimensions.height,
    marginTop: pageMargin,
    left: "0",
    right: "0",
    marginLeft: "auto", // dont change to e.g (transform: translate ...). That will cause blurryness
    marginRight: "auto", // dont change to e.g (transform: translate ...). That will cause blurryness
  };

  const [, drag] = useDrag({
    type: "page",
    item: { documentId, index },
    canDrag: draggable,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <NewPageRenderer
      ref={drag}
      data={data}
      pageIndex={index}
      isScrolling={isScrolling}
      pageDimensions={pageDimensions}
      style={listStyle}
      orientation={pageOrientations?.[index]}
      onPageLoaded={onDocumentLoaded}
    />
  );
};

export default PageList;
