import { useState } from "react";
import { useParams } from "react-router-dom";
import Card from "@components/embla/card";
import { useLocalization } from "@components/localization/localizationProvider";
import { useGetCaseMarkingTagsQuery } from "@services/api/case/caseApi";
import MarkingCloseBtn from "../shared/markingCloseBtn";
import { FocusBoxMarkingModel } from "../../../models/markingModel";
import { EditCardProps } from "../shared/cardProps.model";
import MarkingMetaBlock from "../shared/markingMetaBlock";
import useMarkingCardForm from "../shared/useMarkingCardForm";
import MarkingDeleteBtn from "../shared/markingDeleteBtn";
import MarkingTagsDropdown from "../shared/MarkingTagsDropdown/MarkingTagsDropdown";
import styles from "./focusBoxEdit.module.scss";

const FocusBoxEdit = ({
  marking,
  onMarkingChange,
  onSave,
  onDelete,
  onClose,
  isTemplate,
}: EditCardProps<FocusBoxMarkingModel>) => {
  const [markingsModalOpen, setMarkingsModalOpen] = useState(false);
  
  const { caseId } = useParams();

  if (!caseId) {
    throw new Error("Param \"caseId\" is required");
  }

  const { data: markingTags } = useGetCaseMarkingTagsQuery({ caseId: caseId });

  const localizer = useLocalization();
  const markingCantBeDeleted = marking.presentationList && marking.presentationList.length > 0;

  const { methods, submitHandler } = useMarkingCardForm({
    marking,
    onMarkingChange,
    onSave,
    defaultValues: {
      tags: marking.tags,
    },
  });

  return (
    <Card className={styles.container}>
      <Card.Header>
        {localizer.focusBoxTitle()}
        <MarkingCloseBtn close={onClose} />
      </Card.Header>
      <Card.Body>
        <MarkingMetaBlock marking={marking} />
        <form onSubmit={submitHandler}>
          <MarkingTagsDropdown
            isOpen={markingsModalOpen}
            onOpen={() => setMarkingsModalOpen((prev) => !prev)}
            methods={methods}
            id={`focus-box-tags-${marking.id}`}
            name="tags"
            markingTags={markingTags}
          />
        </form>
      </Card.Body>
      {!isTemplate &&
        <Card.Footer>
          <MarkingDeleteBtn cantBeDeleted={markingCantBeDeleted ?? false} onDelete={onDelete}/>
        </Card.Footer>
      }
    </Card>
  );
};

export default FocusBoxEdit;

