import classNames from "classnames";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDrop } from "react-dnd";
import { DocumentSortType, ExtractDraftSectionModel } from "@services/api/extractDraft/models/extractDraftSectionModel";
import { IconButton } from "@components/button/IconButton/IconButton";
import { EmblaIcon, IconColor } from "@components/embla/emblaIcon";
import { useLocalization } from "@components/localization/localizationProvider";
import {
  useAddExtractDraftDocumentsMutation,
  useDeleteDocumentFromSectionExtractDraftMutation,
  useDeleteSectionMutation,
  useSetSectionSortOrderMutation,
  useTransformSectionDocumentsTitleMutation,
  useUpdateSectionMutation,
} from "@services/api/extractDraft/extractDraftApi";
import { ExtractDraftModel } from "@services/api/extractDraft/models/extractDraftModel";
import DropdownMenu from "@components/menuButton/dropdownMenu";
import { IconTextCallbackItem } from "@components/menuButton/dropdownMenuItems/IconTextDropdownItem";
import ModalMessage from "@components/modal/modalMessage";
import { useDragDropSectionReorder } from "@pages/extractCompositionPage/hooks/useDragDropSectionReorder";
import {
  pageIntervalsToString,
  ReorderExtractDraftSectionDocument,
} from "@pages/extractCompositionPage/hooks/useDragDropSectionDocumentReorder";
import { CaseDocumentModel } from "@services/api/case/models/caseDocumentModel";
import { useIsDndObjectOver } from "@pages/extractCompositionPage/hooks/useIsDndObjectOver";
import { IntervalExtractionType } from "@components/case/extractDraft/addDocumentsToDraftModel";
import { useExtractCompositionContext } from "@pages/extractCompositionPage/extractCompositionContextProvider";
import { handleDragEnd, handleDragStartRemoveGhost } from "@pages/extractCompositionPage/util/util";
import IndicatorLine from "@components/dnd/DragNDropIndicator/IndicatorLine/IndicatorLine";
import { ReactComponent as ScissorsIcon } from "@content/icons/scissors-icon.svg";
import useValidatedForm from "@components/forms/useValidatedForm";
import { ExtractDraftSectionUpdateModel, updateSectionModelValidation } from "@services/api/extractDraft/models/extractDraftSectionUpdateModel";
import FormInput from "@components/forms/FormInput";
import { dndItemTypes } from "../DndItemTypes";
import styles from "./DndSectionMultiple.module.scss";
import Document from "./Document/Document";

type DndSectionMultipleProps = {
  sectionData: ExtractDraftSectionModel;
  extractDraft: ExtractDraftModel;
  onlySelectVerticalLines: boolean;
};

const DndSectionMultiple = ({ sectionData, extractDraft, onlySelectVerticalLines }: DndSectionMultipleProps) => {
  const { documentsSortType } = sectionData;

  const [collapsed, setCollapsed] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteSectionModal, setShowDeleteSectionModal] = useState(false);
  const [showChangeSortORderModal, setShowChangeSortORderModal] = useState(false);
  const [newDocumentsSortOrder, setNewDocumentsSortOrder] = useState<DocumentSortType>(documentsSortType);
  const [isSameSectionDocument, setIsSameSectionDocument] = useState(false);


  const inputRef = useRef<HTMLInputElement>(null);

  const { divRef, position, isDragging: isDraggingSection } = useDragDropSectionReorder(sectionData, extractDraft, !isEditing);
  const { drop: hoverRef, isOver: isDocumentOver } = useIsDndObjectOver({
    type: dndItemTypes.document,
  });
  const { selectedDocumentsReducer } = useExtractCompositionContext();

  const innerDropboxRef = useRef<HTMLDivElement>(null);

  const localizer = useLocalization();

  const [updateSection] = useUpdateSectionMutation();
  const [deleteSection] = useDeleteSectionMutation();
  const [addDocumentsToDraft] = useAddExtractDraftDocumentsMutation();
  const [deleteDocument] = useDeleteDocumentFromSectionExtractDraftMutation();
  const [setDocumentSortOrder] = useSetSectionSortOrderMutation();
  const [transformSectionDocumentsTitle] = useTransformSectionDocumentsTitleMutation();

  const methods = useValidatedForm({
    validationSchema: updateSectionModelValidation(localizer),
    defaultValues: { title: sectionData.title },
  });

  const documentExists = !!sectionData.documents.length;
  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    }
  }, [isEditing]);

  const handleUpdateSectionName = async (model: ExtractDraftSectionUpdateModel) => {
    await updateSection({
      caseId: extractDraft.caseId,
      extractDraftId: extractDraft.id,
      sectionId: sectionData.id,
      model,
    });
    setIsEditing(false);
  };

  const handleMoveDocument = async (dropped: CaseDocumentModel | ReorderExtractDraftSectionDocument) => {
    if ("sectionId" in dropped) {
      const { document } = dropped;

      const addDocumentModel = {
        sectionId: sectionData.id,
        documents: [
          {
            caseDocumentId: document.caseDocumentId,
            pageIntervals: pageIntervalsToString(document.pageIntervals),
            intervalExtractionType: document.intervalExtractionType,
          },
        ],
      };
      addDocumentsToDraft({
        caseId: extractDraft.caseId,
        extractDraftId: extractDraft.id,
        model: addDocumentModel,
      });

      deleteDocument({
        caseId: extractDraft.caseId,
        documentId: document.id,
        extractDraftId: extractDraft.id,
        sectionId: dropped.sectionId,
      });
    } else {
      const selectedDocuments = selectedDocumentsReducer.state.selectedDocuments;

      const addDocumentModel = {
        sectionId: sectionData.id,
        documents: [
          {
            caseDocumentId: dropped.id,
            pageIntervals: "",
            intervalExtractionType: onlySelectVerticalLines ? IntervalExtractionType.PagesWithVerticalLines : IntervalExtractionType.AllDocument,
          },
        ],
      };

      if (selectedDocuments.length > 0) {
        addDocumentModel.documents = selectedDocuments.map((selectedDocument, index) => ({
          sortOrder: index,
          caseDocumentId: selectedDocument.id,
          pageIntervals: "",
          intervalExtractionType: onlySelectVerticalLines ? IntervalExtractionType.PagesWithVerticalLines : IntervalExtractionType.AllDocument,
        }));
      }

      selectedDocumentsReducer.dispatch({ selectedDocuments: [] });
      await addDocumentsToDraft({
        caseId: extractDraft.caseId,
        extractDraftId: extractDraft.id,
        model: addDocumentModel,
      });
    }
  };

  const [, dropDocumentInner] = useDrop({
    accept: dndItemTypes.document,
    drop: (dropped: CaseDocumentModel | ReorderExtractDraftSectionDocument) => {
      if (!documentExists) {
        handleMoveDocument(dropped);
      }
    },
    hover: (item: CaseDocumentModel | ReorderExtractDraftSectionDocument) => {
      if ("sectionId" in item) {
        setIsSameSectionDocument(item.sectionId === sectionData.id);
      }
    },
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
      };
    },
  });

  const handleSortClick = (sortOrder : DocumentSortType) => {
    if (documentsSortType !== sortOrder) {
      setNewDocumentsSortOrder(sortOrder);
      setShowChangeSortORderModal(true);
    }
  };

  const handleChangeDocumentSortOrder = async (newSortOrder: DocumentSortType) => {
    if (documentsSortType === newSortOrder) {
      return;
    }
    const model = {
      documentsSortType: newSortOrder,
      sectionId: sectionData.id,
    };

    await setDocumentSortOrder({
      caseId: extractDraft.caseId,
      extractDraftId: extractDraft.id,
      model,
    });
  };

  const defaultMenuItems = [
    {
      component: (
        <IconTextCallbackItem icon={<EmblaIcon iconName="delete" />} text={localizer.deleteSection()} callback={() => setShowDeleteSectionModal(true)} />
      ),
      key: localizer.deleteSection(),
    },
    {
      component: <IconTextCallbackItem icon={<EmblaIcon iconName="edit" />} text={localizer.renameSection()} callback={() => setIsEditing(true)} />,
      key: localizer.renameSection(),
    },
    {
      component: <IconTextCallbackItem icon={<ScissorsIcon />} text={localizer.extractExhibitNumberFromTitle()} callback={() => transformSectionDocumentsTitle({ caseId: extractDraft.caseId, extractDraftId: extractDraft.id, sectionId: sectionData.id })}/>,
      key: localizer.extractExhibitNumberFromTitle(),
    },
  ];

  dropDocumentInner(hoverRef(innerDropboxRef));

  const sortedDocuments = useMemo(
    () => (documentExists ? [...sectionData.documents].sort((a, b) => a.sortOrder - b.sortOrder) : []),
    [sectionData, documentExists],
  );

  return (
    <>
      <IndicatorLine isVisible={position === "above"}/>
      <div
        onDragStart={!isEditing ? handleDragStartRemoveGhost : undefined}
        onDragEnd={!isEditing ? handleDragEnd : undefined}
        draggable
        ref={divRef}
        className={classNames(styles.container, collapsed ? "pb-2" : "pb-3", {
          [styles.hoverWithDocument]: isDocumentOver && documentsSortType === DocumentSortType.ByDate && documentExists && !isSameSectionDocument,
        })}
        style={{
          opacity: isDraggingSection ? 0.5 : 1,
        }}
      >
        <div
          className={classNames("grid px-3", {
            "pb-1": !collapsed,
          })}
        >
          <div className="row align-items-center">
            {isEditing ? (
              <FormInput
                type="text"
                methods={methods}
                name="title"
                id={`${sectionData.id}-title`}
                formGroupClassName="col-3"
                autoFocus
                onBlur={() => methods.handleSubmit(handleUpdateSectionName)()}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    methods.handleSubmit(handleUpdateSectionName)();
                  }
                }}
              />
            ) : (
              <div className="col-3 d-flex gap-s m-0 align-items-center">
                <h4
                  title={sectionData.title}
                  className={classNames(styles.cursorPointer, styles["section-title"])}
                  onClick={() => handleSortClick(DocumentSortType.Alphabetical)}
                >
                  {sectionData.title}
                </h4>
                <div className={documentsSortType === DocumentSortType.Alphabetical ? styles.sortDesc : styles.sortIcon}></div>
              </div>
            )}
            <h4 className="col-2 d-flex" role="button" onClick={() => handleSortClick(DocumentSortType.ByAppendixNumber)}>
              {localizer.nr()}
              <div className={documentsSortType === DocumentSortType.ByAppendixNumber ? styles.sortDesc : styles.sortIcon}></div>
            </h4>
            <h4 className="col-3">{localizer.pages()}</h4>
            <div
              onClick={() => handleSortClick(DocumentSortType.ByDate)}
              className={classNames("col-2 d-flex gap-s align-items-center", styles.cursorPointer, {
                [styles.cursorPointer]: documentsSortType === DocumentSortType.Custom,
              })}
            >
              <h4>{localizer.date()}</h4>
              <div className={documentsSortType === DocumentSortType.ByDate ? styles.sortDesc : styles.sortIcon}></div>
            </div>
            <div className="col-2 d-flex gap-2 justify-content-between">
              <div className="ellipsisContainer">
                <h4 title={localizer.includeMarkings()} className="ellipsisContainer">
                  {localizer.includeMarkings()}
                </h4>
              </div>
              <div className="d-flex">
                <DropdownMenu buttonNode={<EmblaIcon iconName="overflow" />} menuItems={defaultMenuItems} />
                <IconButton iconName={collapsed ? "arrow-up" : "arrow-down"} color={IconColor.Blue} onClick={() => setCollapsed((prev) => !prev)} />
              </div>
            </div>
          </div>
        </div>
        <div
          ref={innerDropboxRef}
          className={classNames({
            [styles.noDocument]: !documentExists,
            [styles.hoverWithDocumentInner]: isDocumentOver && !documentExists,
          })}
        >
          <div className={classNames(styles.centerText, "flex-grow-1")}>
            {documentExists ? (
              <div className="d-flex flex-column">
                {!collapsed &&
                  sortedDocuments.map((document, index) => (
                    <Document
                      extractDraftId={extractDraft.id}
                      document={document}
                      key={`${document.id}-${sectionData.id}`}
                      caseId={extractDraft.caseId}
                      sectionId={sectionData.id}
                      sortType={sectionData.documentsSortType}
                      index={index}
                      onlySelectVerticalLines={onlySelectVerticalLines}
                      sameSectionDocumentHover={isSameSectionDocument}
                    />
                  ))}
              </div>
            ) : (
              <span>{localizer.dragFileHereFromCase()}</span>
            )}
          </div>
        </div>
      </div>
      <IndicatorLine isVisible={position === "below"}/>
      <ModalMessage
        open={showChangeSortORderModal}
        onClose={() => setShowChangeSortORderModal(false)}
        title={localizer.changeDocumentSorting()}
        description={localizer.changeDocumentSortingWarning()}
        acceptButtonText={localizer.removeSorting()}
        cancelButtonText={localizer.cancel()}
        cancelAction={() => setShowChangeSortORderModal(false)}
        acceptAction={() => handleChangeDocumentSortOrder(newDocumentsSortOrder)}
        modalAcceptType={"primary"}
      />
      <ModalMessage
        open={showDeleteSectionModal}
        title={localizer.deleteSection()}
        description={`${localizer.deleteSectionAreYouSure1()}${
          sectionData.documents.length > 0 ? ` ${localizer.sectionContains()} ${sectionData.documents.length} ${localizer.deleteSectionAreYouSure2()}` : ""
        }`}
        acceptAction={() =>
          deleteSection({
            caseId: extractDraft.caseId,
            extractDraftId: extractDraft.id,
            sectionId: sectionData.id,
          })
        }
        onClose={() => setShowDeleteSectionModal(false)}
      />
    </>
  );
};

export default DndSectionMultiple;
