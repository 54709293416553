import { Fragment, ReactNode, useEffect, useState } from "react";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import Badge from "@components/embla/badge";
import Card from "@components/embla/card";
import useDateFormatter from "src/hooks/useDateFormatter";
import { useAppSelector } from "@hooks";
import { activePresentationSelector } from "@pages/pdfviewer/component/pdfViewerSlice";
import { useLocalization } from "@components/localization/localizationProvider";
import { ReactComponent as PresentationIcon } from "@content/icons/presentation-icon.svg";
import { ReactComponent as IsPrivateIcon } from "@content/icons/hide-eye.svg";
import { Tooltip } from "@components/tooltip/tooltip";
import MarkingIcon from "@pages/pdfviewer/component/shared/markingIcon";
import { useAuth } from "@components/auth/authProvider";
import { EmblaIcon } from "@components/embla/emblaIcon";
import { MarkingType } from "@pages/pdfviewer/component/models/markingType";
import useCaseAccess from "@pages/case/hooks/useCaseAccess";
import Popover from "@components/Popover/Popover";
import { MarkingModel, ReplyModel } from "../../../models/markingModel";
import styles from "./markingPreview.module.scss";
import MarkingReplies from "./replies/markingReplies";

interface SimpleMarkingPreviewProps {
  marking: MarkingModel;
  children?: ReactNode;
  repliesOpen: boolean;
  editMarkingClicked?: () => void;
  onReplyCreateEdit?: (data: ReplyModel) => void;
}

const MarkingPreview = ({ marking, children, repliesOpen, onReplyCreateEdit, editMarkingClicked }: SimpleMarkingPreviewProps) => {
  const localizer = useLocalization();
  const dateFormatter = useDateFormatter();
  const [editStateActive, setEditStateActive] = useState(false);
  const activePresentation = useAppSelector(activePresentationSelector);
  const localization = useLocalization();
  const user = useAuth();
  const { caseId } = useParams();
  const { canEditDeleteAllMarkings } = useCaseAccess(caseId);

  const isInPresentation = marking.presentationList && marking.presentationList.length > 0;
  const isInActivePresentation = activePresentation && isInPresentation &&  marking.presentationList && marking.presentationList.some((s) => s.presentationId === activePresentation.id);

  useEffect(() => {
    if (repliesOpen && (user.user.id === marking.owner.userId || (canEditDeleteAllMarkings() && marking.type !== MarkingType.Comment)) && editMarkingClicked)
      setEditStateActive(true);
    else
      setEditStateActive(false);
  }, [canEditDeleteAllMarkings, editMarkingClicked, marking.owner.userId, marking.type, repliesOpen, user.user.id]);

  return (
    <Card className="mb-0">
      <Card.Body className="small">
        <div className="row">
          <div className="col-6 d-flex align-items-start">
            <div className="d-flex align-items-center">
              <div className={classNames("flex-shrink-0", styles.markingIcon)}>
                <MarkingIcon markingType={marking.type} />
              </div>
              <div className="margin-left-s d-flex">
                <div className={classNames("small", "margin-bottom-xs", "text-nowrap", "text-truncate", styles.nameContainer, marking.isPrivate && styles.isPrivate, styles.tagNameOverflow)}>
                  {marking.owner.name}
                  {marking.isPrivate &&
                  <Tooltip message={localization.onlyVisible()} placement="top">
                    <IsPrivateIcon className="margin-left-xs"/>
                  </Tooltip>
                  }</div>

                <div className="small margin-bottom-0 margin-left-s subtle">{marking.creationDate && dateFormatter.shortDateWithoutYear(marking.creationDate)}</div>
              </div>
            </div>
          </div>
          <div className="col-6 d-flex flex-column justify-content-center align-items-end">
            <div className={classNames("d-flex", repliesOpen ? "justify-content-end" : "justify-content-center")}>
              <div className={classNames("d-flex", repliesOpen ? "flex-column" : "flex-row", "align-self-center", styles.presentationSubjectsContainer, editStateActive && styles.wide)}>
                {
                  isInPresentation && marking.presentationList && (
                    <div className={classNames("align-self-end", styles.presentationsContainer)}>
                      <Popover placement="bottom" hoverDelay={500} content={
                        <div className="p-2 card">
                          <ul className="m-0 pl-4">
                            {marking.presentationList.map((p) => (
                              <li className="my-1" key={p.presentationId}>{p.presentationName}</li>
                            ))}
                          </ul>
                        </div>
                      }>
                        <Badge
                          appearance={isInActivePresentation ? "primary" : "default"}
                          pill
                          size="wide"
                          className={styles.presentationBadge}>
                          <PresentationIcon className={classNames(styles.presentationBadgeIcon, isInActivePresentation && "color-white")} />
                          <span className={styles.presentationBadgeText}>{ marking.presentationList.length === 1 ? marking.presentationList[0].presentationName : `${marking.presentationList.length} ${localization.presentations().toLowerCase()}` }</span>
                        </Badge>
                      </Popover>
                    </div>
                  )
                }
                {repliesOpen
                  ?
                  (<div className={`${styles.expandTaglist}`}>
                    {marking.tags?.map((tag, index) => (
                      <Fragment key={index + tag.tagName}>
                        <Tooltip key={index + tag.tagName} message={tag.tagName} placement="top-start">
                          <Badge
                            appearance="success-light"
                            pill
                            size="wide"
                            style={{ order: index }}
                            className={`${styles.tagNameOverflow}`}
                          >
                            {tag.tagName}
                          </Badge>
                        </Tooltip>
                      </Fragment>
                    ))}
                  </div>)
                  :
                  (<div className="d-flex justify-content-end margin-left-xs">
                    <div className={classNames(styles.subjectsContainer)}>
                      { marking.tags.length > 0 && (
                        <Badge
                          appearance="success-light"
                          pill
                          size="wide"
                          className={`${styles.tagNameOverflow}`}
                        >
                          <span>{ marking.tags.length === 1 ? marking.tags[0].tagName : `${marking.tags.length} ${localization.tagsPlural().toLowerCase()}` }</span>
                        </Badge>)
                      }
                    </div>
                  </div>)}
              </div>
              {editStateActive &&
              (
                <div className="margin-left-s">
                  <button
                    className={classNames("btn btn-default btn-icon")}
                    onClick={editMarkingClicked}
                  >
                    <EmblaIcon iconName="edit" />
                  </button>
                </div>
              )}
            </div>

          </div>
        </div>
      </Card.Body>
      {children && (
        <Card.Body>
          {children}
        </Card.Body>
      )}
      {repliesOpen
        ?
        <MarkingReplies markingModel={marking} onReplyCreateEdit={onReplyCreateEdit}></MarkingReplies>

        : marking.replies && marking.replies?.length > 0 &&
        <Card.Body className="small">
          <div className="small subtle">
            {marking.replies.length} {marking.replies.length === 1 ? localizer.reply() : localizer.replies()}
          </div>
        </Card.Body>
      }
    </Card>
  );
};

export default MarkingPreview;
