import classNames from "classnames";
import { useParams } from "react-router-dom";
import Button from "@components/embla/button";
import { ReactComponent as FlipIcon } from "@content/icons/flip-down.svg";
import { useAppSelector } from "@hooks";
import { useGetDocumentPagesQuery, useRotateCaseDocumentMutation } from "@services/api/document/caseDocumentApi";
import { PageOrientation } from "@services/api/document/models/rotateCaseDocumentModel";
import { Tooltip } from "@components/tooltip/tooltip";
import { useLocalization } from "@components/localization/localizationProvider";
import { mostVisiblePageIndexSelector, pdfTypeIdSelector } from "../pdfViewerSlice";
import usePageMarkings from "../page/canvas/usePageMarkings";
import styles from "./FlipControlButton.module.scss";

const FlipControlButton = () => {
  const localizer = useLocalization();

  const documentId = useAppSelector(pdfTypeIdSelector);
  const mostVisiblePageIndex = useAppSelector(mostVisiblePageIndexSelector);
  const { caseId } = useParams<{ caseId: string }>();
  const [rotateDocument, { isLoading: isLoadingRotate }] = useRotateCaseDocumentMutation();

  const { markings } = usePageMarkings(mostVisiblePageIndex);
  
  const getNewRotation = (currentRotation: number) : PageOrientation => {
    return currentRotation + 90 > 270 ? 0 : currentRotation + 90 as PageOrientation;
  };
  
  const { data: pages, isLoading: isLoadingPages } = useGetDocumentPagesQuery(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    { caseId: caseId!, documentId: documentId! },
    { skip: !documentId || !caseId },
  );
  
  const handleRotateDocument = async () => {
    if (isLoadingRotate || isLoadingPages || !documentId) {
      return;
    }

    if (caseId && pages) {
      const currentPageId = pages.find((page) => page.pageIndex === mostVisiblePageIndex)?.id;
      if (currentPageId) {
        rotateDocument({ caseId, model : { orientation: getNewRotation(pages[mostVisiblePageIndex].pageOrientation),
        }, caseDocumentPageId: currentPageId, caseDocumentId: documentId });
      }
    }
  };

  return (
    <Tooltip message={markings.length > 0 && localizer.cantRotatePageWhenMarkings()}>
      <Button
        disabled={isLoadingRotate || isLoadingPages || markings.length > 0}
        borderless
        className={classNames("btn-icon ml-2")}
        onClick={handleRotateDocument}
      >
        <FlipIcon className={styles.blackIcon} />
      </Button>
    </Tooltip>
  );
};

export default FlipControlButton;