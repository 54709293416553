import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { MaybePromise } from "@reduxjs/toolkit/dist/query/tsHelpers";
import { FetchBaseQueryError, FetchBaseQueryMeta } from "@reduxjs/toolkit/dist/query";
import { TitlePageModel } from "@components/case/presentationPage/titlePageModel";
import { BulkAddToPresentations } from "@pages/pdfviewer/component/models/bulkAddToPresentations";
import { CasePresentationModel } from "@components/case/presentation/casePresentationModel";
import { ThunkExtras } from "@store";
import { PdfType } from "@pages/pdfviewer/component/pdfViewerSlice";
import { markingsApi } from "@pages/pdfviewer/component/markingsApi";
import { ExportBaseModel } from "@services/api/case/models/exportBaseModel";
import { AddOrUpdateNoteModel } from "@services/api/casePresentation/models/AddOrUpdateNoteModel";
import { EditPresentationPageNotSizeModel } from "@services/api/casePresentation/models/editPresentationPageNotSizeModel";
import baseApi, { apiTags } from "../baseApi";
import presentationApi from "../presentation/presentationApi";
import { CreatePresentationsModel } from "../casePresentation/models/createPresentationsModel";
import { PresentationPageEditMarkingsModel } from "./models/presentationPageEditMarkingsModel";
import { CasePresentationListModel } from "./models/casePresentationListModel";
import { DownloadFileUrlResponseModel } from "./models/downloadFileUrlResponseModel";
import { ReorderNoteModel } from "./models/reorderNoteModel";
import { OrientationModel } from "./models/orientationModel";


const caseUrl = "/api/case";
const casePresentationUrl = (caseId: string) => `${caseUrl}/${caseId}/presentation`;
const bulkAddPagesToPresentationsUrl = (caseId: string) => `${casePresentationUrl(caseId)}/bulkPages`;

const presentationUrl = (caseId: string) => `${caseUrl}/${caseId}/presentation`;
const addManyPresentationsUrl = (caseId: string) => `${presentationUrl(caseId)}/many`;
const reorderPresentationsUrl = (caseId: string) => `${presentationUrl(caseId)}/reorder`;
const editPresentationUrl = (caseId: string, presentationId: string) => `${presentationUrl(caseId)}/${presentationId}`;
const exportPresentationUrl = (caseId: string, presentationId: string) => `${presentationUrl(caseId)}/${presentationId}/export`;
const exportPresentationDownloadUrlUrl = (caseId: string, presentationId: string, exportPresentationId: string) => `${presentationUrl(caseId)}/${presentationId}/export/${exportPresentationId}`;
const deletePresentationUrl = (caseId: string, presentationId: string) => `${presentationUrl(caseId)}/${presentationId}`;

const reorderPresentationsPageUrl = (caseId: string, presentationId: string) => `${presentationUrl(caseId)}/${presentationId}/page/reorder`;
const presentationPageUrl = (caseId: string, presentationId: string, presentationPageId: string) => `${presentationUrl(caseId)}/${presentationId}/page/${presentationPageId}`;
const editPresentationPageMarkingsUrl = (caseId: string, presentationId: string, presentationPageId: string) => `${presentationUrl(caseId)}/${presentationId}/page/${presentationPageId}/markings`;
const addTitlePageToPresentationUrl = (caseId: string, presentationId: string) => `${presentationUrl(caseId)}/${presentationId}/page/titlePage`;
const editPresentationTitlePageUrl = (caseId: string, presentationId: string, presentationPageId: string) => `${presentationUrl(caseId)}/${presentationId}/page/titlePage/${presentationPageId}`;
const addPresentationPageNoteUrl = (caseId: string, presentationId: string, presentationPageId: string) => `${presentationUrl(caseId)}/${presentationId}/page/${presentationPageId}/note`;
const movePresentationNoteUrl = (caseId: string, presentationId: string, presentationPageId: string) => `${presentationUrl(caseId)}/${presentationId}/page/${presentationPageId}/moveNote`;
const pageOrientationUrl = (caseId: string, presentationId: string, presentationPageId: string) => `${presentationUrl(caseId)}/${presentationId}/page/${presentationPageId}/orientation`;
const editPresentationPageNoteSizeUrl = (caseId: string, presentationId: string, presentationPageId: string) => `${presentationUrl(caseId)}/${presentationId}/page/${presentationPageId}/noteSize`;
const updatePresentationPageNoteUrl = (caseId: string, presentationId: string, presentationPageId: string, presentationPageNoteId: string) => `${addPresentationPageNoteUrl(caseId, presentationId, presentationPageId)}/${presentationPageNoteId}`;
const deletePresentationPageNoteUrl = (caseId: string, presentationId: string, presentationPageId: string, presentationPageNoteId: string) => `${addPresentationPageNoteUrl(caseId, presentationId, presentationPageId)}/${presentationPageNoteId}`;


const casePresentationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    bulkAddToPresentations: builder.mutation<void, { caseId: string, model: BulkAddToPresentations }>({
      query: ({ caseId, model }) => ({
        url: bulkAddPagesToPresentationsUrl(caseId),
        method: "POST",
        body: model,
      }),
      invalidatesTags: (result, _, { caseId }) => result ? [
        { type: apiTags.case, id: caseId },
        apiTags.casePresentation,
      ] : [],
    }),

    getCasePresentations: builder.query<CasePresentationListModel[], string>({
      query: (caseId) => presentationUrl(caseId),
      providesTags: (result) => [apiTags.casePresentation, ...result?.map((s) => ({ type: apiTags.casePresentation, id: s.id })) ?? []],
    }),

    addPresentations: builder.mutation<string[], { caseId: string, model: CreatePresentationsModel }>({
      query: ({ caseId, model }) => ({
        url: addManyPresentationsUrl(caseId),
        method: "POST",
        body: model,
      }),
      invalidatesTags: (result, _, { caseId }) => result ? [
        { type: apiTags.case, id: caseId },
        apiTags.casePresentation,
      ] : [],
    }),

    reorderPresentations: builder.mutation<boolean, { caseId: string, draggedPresentationId: string, newIndex: number }>({
      query: ({ caseId, draggedPresentationId, newIndex }) => ({
        url: reorderPresentationsUrl(caseId),
        method: "PUT",
        body: {
          draggedPresentationId: draggedPresentationId,
          newIndex: newIndex,
        },
      }),
      invalidatesTags: (result, _, { caseId, draggedPresentationId }) => result ? [
        { type: apiTags.case, id: caseId },
        { type: apiTags.casePresentation, id: draggedPresentationId },
      ] : [],
      // Optimistic update
      onQueryStarted: async ({ caseId, draggedPresentationId, newIndex }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          casePresentationApi.util.updateQueryData("getCasePresentations", caseId, (draft) => {
            const draggedIndex = draft.findIndex((x) => x.id === draggedPresentationId);
            const draggedItem = draft[draggedIndex];
            draft.splice(draggedIndex, 1);
            draft.splice(newIndex, 0, draggedItem);
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    editPresentation: builder.mutation<undefined, { caseId: string, presentationId: string, model: CasePresentationModel }>({
      query: ({ caseId, presentationId, model }) => ({
        url: editPresentationUrl(caseId, presentationId),
        method: "PUT",
        body: model,
      }),
      invalidatesTags: (_, error, { presentationId }) => !error ? [
        { type: apiTags.casePresentation, id: presentationId },
      ] : [],
    }),

    exportPresentation: builder.mutation<string, { caseId: string, presentationId: string, model: ExportBaseModel }>({
      query: ({ caseId, presentationId, model }) => ({
        url: exportPresentationUrl(caseId, presentationId),
        method: "POST",
        body: model,
      }),
    }),

    getPresentationExportDownloadUrl: builder.query<DownloadFileUrlResponseModel, { caseId: string, presentationId: string, presentationExportId: string }>({
      query: ({ caseId, presentationId, presentationExportId }) => exportPresentationDownloadUrlUrl(caseId, presentationId, presentationExportId),
    }),

    deletePresentation: builder.mutation<boolean, { caseId: string, presentationId: string }>({
      query: ({ caseId, presentationId }) => ({
        url: deletePresentationUrl(caseId, presentationId),
        method: "DELETE",
      }),
      invalidatesTags: (result, _, { caseId, presentationId }) => result ? [
        { type: apiTags.case, id: caseId },
        { type: apiTags.casePresentation, id: presentationId },
      ] : [],
    }),

    // PresentationPages
    editPresentationPageMarkings: builder.mutation<string, { caseId: string, presentationId: string, presentationPageId: string, model: PresentationPageEditMarkingsModel }>({
      queryFn: (model, { extra }, _, baseQuery) => {
        const { presentationHubConnectionManager } = (extra as ThunkExtras);
        return baseQuery({
          url: editPresentationPageMarkingsUrl(model.caseId, model.presentationId, model.presentationPageId),
          method: "PUT",
          body: model.model,
          headers: presentationHubConnectionManager.getSignalRHeader(model.presentationId),
        }) as MaybePromise<QueryReturnValue<string, FetchBaseQueryError, FetchBaseQueryMeta>>;
      },
      invalidatesTags: (result, error, { caseId, presentationId, presentationPageId }) => !error ? [
        { type: apiTags.casePresentation, id: presentationId },
        { type: apiTags.casePresentationPage, id: presentationPageId },
        apiTags.markings,
      ] : [],
    }),

    addPresentationPageNote: builder.mutation<string,  { caseId: string, presentationId: string, presentationPageId: string, model: AddOrUpdateNoteModel }>({
      queryFn: (model, { extra }, _, baseQuery) => {
        const { presentationHubConnectionManager } = (extra as ThunkExtras);
        return baseQuery({
          url: addPresentationPageNoteUrl(model.caseId, model.presentationId, model.presentationPageId),
          method: "POST",
          body: model.model,
          headers: presentationHubConnectionManager.getSignalRHeader(model.presentationId),
        }) as MaybePromise<QueryReturnValue<string, FetchBaseQueryError, FetchBaseQueryMeta>>;
      },
      invalidatesTags: (result, error, { caseId, presentationId, presentationPageId }) => !error ? [
        { type: apiTags.casePresentation, id: presentationId },
        { type: apiTags.casePresentationPage, id: presentationPageId },
      ] : [],
    }),

    updatePresentationPageNote: builder.mutation<string,  { caseId: string, presentationId: string, presentationPageId: string, presentationPageNoteId: string, model: AddOrUpdateNoteModel }>({
      queryFn: (model, { extra }, _, baseQuery) => {
        const { presentationHubConnectionManager } = (extra as ThunkExtras);
        return baseQuery({
          url: updatePresentationPageNoteUrl(model.caseId, model.presentationId, model.presentationPageId, model.presentationPageNoteId),
          method: "PUT",
          body: model.model,
          headers: presentationHubConnectionManager.getSignalRHeader(model.presentationId),
        }) as MaybePromise<QueryReturnValue<string, FetchBaseQueryError, FetchBaseQueryMeta>>;
      },
      // Optimistic update
      onQueryStarted: async ({ caseId, presentationId, presentationPageId, presentationPageNoteId, model }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          presentationApi.util.updateQueryData("getPresentationPages", presentationId, (draft) => {
            const pageIndex = draft.findIndex((x) => x.id === presentationPageId);
            if (pageIndex >= 0) {
              const page =  { ...draft[pageIndex] };
              const noteIndex = page.notes.findIndex((x) => x.id === presentationPageNoteId);

              if (noteIndex >= 0) {
                const note = { ...page.notes[noteIndex] };
                note.note = model.note;

                page.notes[noteIndex] = note;
                draft[pageIndex] = page;
              }
            }
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          // If the mutation fails, revert to the original value
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, { caseId, presentationId, presentationPageId }) => !error ? [
        { type: apiTags.casePresentation, id: presentationId },
        { type: apiTags.casePresentationPage, id: presentationPageId },
      ] : [],
    }),

    deletePresentationPageNote: builder.mutation<string,  { caseId: string, presentationId: string, presentationPageId: string, presentationPageNoteId: string }>({
      queryFn: (model, { extra }, _, baseQuery) => {
        const { presentationHubConnectionManager } = (extra as ThunkExtras);
        return baseQuery({
          url: deletePresentationPageNoteUrl(model.caseId, model.presentationId, model.presentationPageId, model.presentationPageNoteId),
          method: "DELETE",
          headers: presentationHubConnectionManager.getSignalRHeader(model.presentationId),
        }) as MaybePromise<QueryReturnValue<string, FetchBaseQueryError, FetchBaseQueryMeta>>;
      },
      invalidatesTags: (result, error, { caseId, presentationId, presentationPageId }) => !error ? [
        { type: apiTags.casePresentation, id: presentationId },
        { type: apiTags.casePresentationPage, id: presentationPageId },
      ] : [],
    }),

    movePresentationNote: builder.mutation<string,  { caseId: string, presentationId: string, presentationPageId: string, model: ReorderNoteModel }>({
      query: (model) => ({
        url: movePresentationNoteUrl(model.caseId, model.presentationId, model.presentationPageId),
        method: "PUT",
        body: model.model,
      }),
      onQueryStarted: async ({ presentationId, presentationPageId, model }, { dispatch, queryFulfilled }) => {
        const presentationPagesPatch = dispatch(
          presentationApi.util.updateQueryData("getPresentationPages", presentationId, (draft) => {
            const page = draft.find((t) => t.id === presentationPageId);
            
            if (page) {
              const sortedNotes = page.notes.sort((a, b) => a.order - b.order);
              const draggedNote = sortedNotes.find((t) => t.id === model.noteId);
              
              const newNotes = sortedNotes.filter((t) => t.id !== model.noteId);
              if (draggedNote) {
                newNotes.splice(model.newSortOrder, 0, draggedNote);
                newNotes.forEach((t, i) => t.order = i);
              }
              page.notes = newNotes;
            }
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          presentationPagesPatch.undo();
        }
      },
    }),

    changePageOrientation: builder.mutation<string,  { caseId: string, presentationId: string, presentationPageId: string, model: OrientationModel }>({
      queryFn: (model, { extra }, _, baseQuery) => {
        const { presentationHubConnectionManager } = (extra as ThunkExtras);
        return baseQuery({
          url: pageOrientationUrl(model.caseId, model.presentationId, model.presentationPageId),
          method: "PUT",
          body: model.model,
          headers: presentationHubConnectionManager.getSignalRHeader(model.presentationId),
        }) as MaybePromise<QueryReturnValue<string, FetchBaseQueryError, FetchBaseQueryMeta>>;
      },
      onQueryStarted: async ({ presentationId, presentationPageId, model }, { dispatch, queryFulfilled }) => {
        const presentationPagesPatch = dispatch(
          presentationApi.util.updateQueryData("getPresentationPages", presentationId, (draft) => {
            const flippedPage = draft.find((t) => t.id === presentationPageId);
            if (flippedPage && (model.orientation === 0 || model.orientation === 90 || model.orientation === 180 || model.orientation === 270) ) {
              flippedPage.orientation = model.orientation;
            }
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          presentationPagesPatch.undo();
        }
      },
      invalidatesTags: (error) => !error ? [
        apiTags.caseDocumentPage,
      ] : [],
    }),

    addTitlePageToPresentation: builder.mutation<void, { caseId: string, presentationId: string, model: TitlePageModel }>({
      queryFn: (arg, { extra }, _, baseQuery) => {
        const { presentationHubConnectionManager } = (extra as ThunkExtras);
        return baseQuery({
          url: addTitlePageToPresentationUrl(arg.caseId, arg.presentationId),
          method: "POST",
          body: arg.model,
          headers: presentationHubConnectionManager.getSignalRHeader(arg.presentationId),
        }) as MaybePromise<QueryReturnValue<void, FetchBaseQueryError, FetchBaseQueryMeta>>;
      },
      invalidatesTags: (result, error, { caseId, presentationId }) => !error ? [
        { type: apiTags.casePresentation, id: presentationId },
        { type: apiTags.casePresentationPdfFilesUrl, id: presentationId },
        apiTags.casePresentationPage,
      ] : [],
    }),

    editPresentationTitlePage: builder.mutation<void, { caseId: string, presentationId: string, presentationPageId: string, model: TitlePageModel }>({
      queryFn: (arg, { extra }, _, baseQuery) => {
        const { presentationHubConnectionManager } = (extra as ThunkExtras);
        return baseQuery({
          url: editPresentationTitlePageUrl(arg.caseId, arg.presentationId, arg.presentationPageId),
          method: "PUT",
          body: arg.model,
          headers: presentationHubConnectionManager.getSignalRHeader(arg.presentationId),
        }) as MaybePromise<QueryReturnValue<void, FetchBaseQueryError, FetchBaseQueryMeta>>;
      },
      invalidatesTags: (result, error, { caseId, presentationId, presentationPageId }) => !error ? [
        { type: apiTags.casePresentation, id: presentationId },
        { type: apiTags.casePresentationPdfFilesUrl, id: presentationId },
        { type: apiTags.casePresentationPage, id: presentationPageId },
      ] : [],
    }),

    reorderPresentationsPages: builder.mutation<undefined, { caseId: string, presentationId: string, presentationPageId: string, oldIndex: number, newIndex: number, userId: string }>({
      queryFn: (arg, { extra }, _, baseQuery) => {
        const { presentationHubConnectionManager } = (extra as ThunkExtras);
        return baseQuery({
          url: reorderPresentationsPageUrl(arg.caseId, arg.presentationId),
          method: "POST",
          body: {
            presentationPageId: arg.presentationPageId,
            newIndex: arg.newIndex,
          },
          headers: presentationHubConnectionManager.getSignalRHeader(arg.presentationId),
        }) as MaybePromise<QueryReturnValue<undefined, FetchBaseQueryError, FetchBaseQueryMeta>>;
      },
      invalidatesTags: (_, error, { presentationId }) => !error ? [
        { type: apiTags.casePresentation, id: presentationId },
        apiTags.markings,
      ] : [],
      // Optimistic update
      onQueryStarted: async ({ presentationId, oldIndex, newIndex, userId }, { dispatch, queryFulfilled }) => {
        const seqPagesPatch = dispatch(
          presentationApi.util.updateQueryData("getPresentationPages", presentationId, (draft) => {
            const [draggedItem] = draft.splice(oldIndex, 1);
            draft.splice(newIndex, 0, draggedItem);
            for (let i = Math.min(oldIndex, newIndex); i <= Math.max(oldIndex, newIndex); i++) {
              draft[i].order = i;
            }
          }),
        );
        const markingsPatch = dispatch(
          markingsApi.util.updateQueryData("getMarkings", { markingsArgs: { origin: PdfType.Presentation, pdfTypeId: presentationId }, userId }, (draft) => {
            const temp = draft[oldIndex];
            const direction = oldIndex < newIndex ? 1 : -1;
            for (let i = oldIndex; i !== newIndex; i += direction) {
              draft[i] = draft[i + direction];
            }
            draft[newIndex] = temp;
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          seqPagesPatch.undo();
          markingsPatch.undo();
        }
      },
    }),

    removePresentationPage: builder.mutation<string, { caseId: string, presentationPageId: string, presentationId: string }>({
      queryFn: (arg, { extra }, _, baseQuery) => {
        const { presentationHubConnectionManager } = (extra as ThunkExtras);
        return baseQuery({
          url: presentationPageUrl(arg.caseId, arg.presentationId, arg.presentationPageId),
          method: "DELETE",
          headers: presentationHubConnectionManager.getSignalRHeader(arg.presentationId),
        }) as MaybePromise<QueryReturnValue<string, FetchBaseQueryError, FetchBaseQueryMeta>>;
      },
      invalidatesTags: (caseDocumentPageId, error, model) => !error ? [
        { type: apiTags.casePresentationPage, id: model.presentationPageId },
        apiTags.markings,
        apiTags.casePresentation,
      ] : [],
    }),
    editPresentationPageNoteSize: builder.mutation<string, { caseId: string, presentationPageId: string, presentationId: string, model: EditPresentationPageNotSizeModel }>({
      queryFn: (arg, { extra }, _, baseQuery) => {
        const { presentationHubConnectionManager } = (extra as ThunkExtras);
        return baseQuery({
          url: editPresentationPageNoteSizeUrl(arg.caseId, arg.presentationId, arg.presentationPageId),
          method: "PUT",
          body: arg.model,
          headers: presentationHubConnectionManager.getSignalRHeader(arg.presentationId),
        }) as MaybePromise<QueryReturnValue<string, FetchBaseQueryError, FetchBaseQueryMeta>>;
      },
      invalidatesTags: (result, error, { caseId, presentationId, presentationPageId }) => (!error) ? [
        { type: apiTags.casePresentation, id: presentationId },
        { type: apiTags.casePresentationPage, id: presentationPageId },
      ] : [],
      // Optimistic update
      onQueryStarted: async ({ caseId, presentationPageId, presentationId, model }, { dispatch, queryFulfilled }) => {
        const patch = dispatch(
          presentationApi.util.updateQueryData("getPresentationPages", presentationId, (draft) => {
            const page = draft.find((x) => x.id === presentationPageId);
            if (page) {
              page.noteSize = model.noteSize;
            }
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patch.undo();
        }
      },
    }),

  }),
});

export default casePresentationApi;
export const {
  useBulkAddToPresentationsMutation,
  useGetCasePresentationsQuery,
  useDeletePresentationPageNoteMutation,
  useAddPresentationsMutation,
  useReorderPresentationsMutation,
  useEditPresentationMutation,
  useExportPresentationMutation,
  useGetPresentationExportDownloadUrlQuery,
  useDeletePresentationMutation,
  useRemovePresentationPageMutation,
  useAddPresentationPageNoteMutation,
  useEditPresentationPageMarkingsMutation,
  useUpdatePresentationPageNoteMutation,
  useAddTitlePageToPresentationMutation,
  useEditPresentationTitlePageMutation,
  useReorderPresentationsPagesMutation,
  useMovePresentationNoteMutation,
  useChangePageOrientationMutation,
  useEditPresentationPageNoteSizeMutation,
} = casePresentationApi;


